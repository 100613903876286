import { track } from 'utils/tracking';

import {
  AGENCY_PRESS_RELEASES_CONTEXT,
  CBO_COST_ESTIMATES_CONTEXT,
  COMMITEE_REPORTS_CONTEXT,
  CONGRESSIONAL_PRESS_RELEASES_CONTEXT,
  CONGRESSIONAL_RECORD_ITEM_CONTEXT,
  CONTRACTS_CONTEXT,
  CRS_REPORTS_CONTEXT,
  DEFENSE_BUDGETS_CONTEXT,
  DEAR_COLLEAGUE_LETTER_CONTEXT,
  EXECUTIVE_ORDERS_CONTEXT,
  GAO_REPORTS_CONTEXT,
  GRANTS_CONTEXT,
  LIVE_HEARINGS_CONTEXT,
  OFICIAL_HEARINGS_CONTEXT,
  REGULATIONS_CONTEXT,
  SAP_REPORTS_CONTEXT,
  STATE_OF_THE_UNION_ADRESSES_CONTEXT,
  THIRD_PARTY_REPORTS_CONTEXT,
  TREATY_DOCUMENTS_CONTEXT,
  WHITE_HOUSE_PRESS_BRIEFINGS_CONTEXT,
} from './contexts';

function createSearchedEvent(context) {
  return () => {
    track('searched', context);
  };
}

function createResultVisitedEvent(context) {
  return () => {
    track('result visited', context);
  };
}

export const trackAgencyPressSearched = createSearchedEvent(AGENCY_PRESS_RELEASES_CONTEXT);

export const trackAgencyPressResultVisited = createResultVisitedEvent(AGENCY_PRESS_RELEASES_CONTEXT);

export const trackCboReportsSearch = createSearchedEvent(CBO_COST_ESTIMATES_CONTEXT);

export const trackCboReportsResultVisited = createResultVisitedEvent(CBO_COST_ESTIMATES_CONTEXT);

export const trackCommiteeReportsSearched = createSearchedEvent(COMMITEE_REPORTS_CONTEXT);

export const trackCommiteeReportsResultVisited = createResultVisitedEvent(COMMITEE_REPORTS_CONTEXT);

export const trackCongressionalPressReleasesSearched = createSearchedEvent(CONGRESSIONAL_PRESS_RELEASES_CONTEXT);

export const trackCongressionalPressReleasesResultVisited = createResultVisitedEvent(CONGRESSIONAL_PRESS_RELEASES_CONTEXT);

export const trackCongressionalRecordItemSearched = createSearchedEvent(CONGRESSIONAL_RECORD_ITEM_CONTEXT);

export const trackCongressionalRecordItemResultVisited = createResultVisitedEvent(CONGRESSIONAL_RECORD_ITEM_CONTEXT);

export const trackContractsSearched = createSearchedEvent(CONTRACTS_CONTEXT);

export const trackContractsResultVisited = createResultVisitedEvent(CONTRACTS_CONTEXT);

export const trackCrsReportsSearched = createSearchedEvent(CRS_REPORTS_CONTEXT);

export const trackCrsReportsResultVisited = createResultVisitedEvent(CRS_REPORTS_CONTEXT);

export const trackDefenseBudgetsSearched = createSearchedEvent(DEFENSE_BUDGETS_CONTEXT);

export const trackDefenseBudgetsResultVisited = createResultVisitedEvent(DEFENSE_BUDGETS_CONTEXT);

export const trackColleagueLetterSearched = createSearchedEvent(DEAR_COLLEAGUE_LETTER_CONTEXT);

export const trackColleagueLetterResultVisited = createResultVisitedEvent(DEAR_COLLEAGUE_LETTER_CONTEXT);

export const trackExecutivesOrdersSearched = createSearchedEvent(EXECUTIVE_ORDERS_CONTEXT);

export const trackExecutivesOrdersResultVisited = createResultVisitedEvent(EXECUTIVE_ORDERS_CONTEXT);

export const trackGaoReportsSearched = createSearchedEvent(GAO_REPORTS_CONTEXT);

export const trackGaoReportsResultVisited = createResultVisitedEvent(GAO_REPORTS_CONTEXT);

export const trackGrantsSearched = createSearchedEvent(GRANTS_CONTEXT);

export const trackGrantsResultVisited = createResultVisitedEvent(GRANTS_CONTEXT);

export const trackLiveHearingsSearched = createSearchedEvent(LIVE_HEARINGS_CONTEXT);

export const trackLiveHearingsResultVisited = createResultVisitedEvent(LIVE_HEARINGS_CONTEXT);

export const trackOficialHearingsSearched = createSearchedEvent(OFICIAL_HEARINGS_CONTEXT);

export const trackOficialHearingsResultVisited = createResultVisitedEvent(OFICIAL_HEARINGS_CONTEXT);

export const trackRegulationsSearched = createSearchedEvent(REGULATIONS_CONTEXT);

export const trackRegulationsResultVisited = createResultVisitedEvent(REGULATIONS_CONTEXT);

export const trackSapReportsSearched = createSearchedEvent(SAP_REPORTS_CONTEXT);

export const trackSapReportsResultVisited = createResultVisitedEvent(SAP_REPORTS_CONTEXT);

export const trackStateOfTheUnionSearched = createSearchedEvent(STATE_OF_THE_UNION_ADRESSES_CONTEXT);

export const trackStateOfTheUnionResultVisited = createResultVisitedEvent(STATE_OF_THE_UNION_ADRESSES_CONTEXT);

export const trackThirdPartyReportsSearched = createSearchedEvent(THIRD_PARTY_REPORTS_CONTEXT);

export const trackThirdPartyReportsResultVisited = createResultVisitedEvent(THIRD_PARTY_REPORTS_CONTEXT);

export const trackTreatyDocumentsSearched = createSearchedEvent(TREATY_DOCUMENTS_CONTEXT);

export const trackTreatyDocumentsResultVisited = createResultVisitedEvent(TREATY_DOCUMENTS_CONTEXT);

export const trackWhiteHousePressBriefingsSearched = createSearchedEvent(WHITE_HOUSE_PRESS_BRIEFINGS_CONTEXT);

export const trackWhiteHousePressBriefingsResultVisited = createResultVisitedEvent(WHITE_HOUSE_PRESS_BRIEFINGS_CONTEXT);
