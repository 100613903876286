import Notifications from 'components/Stakeholder/Lookup/utils/Notifications';

export function handleLinkListClick({
  id,
  name,
  columnKeyName,
  onFetch,
  onResetSelectedItems,
  isTablePageView,
}) {
  return () => {
    onFetch({
      searchData: {
        options: {
          [columnKeyName]: [{ value: id, label: name }],
        },
      },
      isTablePageView,
    })
      .catch((error) => {
        Notifications.defaultFetchDataFailed();
        throw error;
      });

    onResetSelectedItems && onResetSelectedItems();
  };
}

export function getColumnLabelBySortingKey(columns, key) {
  const result = columns.find(column => column.sortingKey === key) || {};

  return result.label || null;
}

export function getTableEmptyMessage(type = 'data', userType = 'staffer') {
  return `Sorry, we currently have no ${type} for this ${userType}`;
}
