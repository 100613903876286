
export const FEDERAL_DOC_TROVE_CONTEXT = 'intel - federal doc trove';


export const AGENCY_PRESS_RELEASES_CONTEXT = `${FEDERAL_DOC_TROVE_CONTEXT} - agency press releases`;
export const CBO_COST_ESTIMATES_CONTEXT = `${FEDERAL_DOC_TROVE_CONTEXT} - cbo cost estimates`;
export const COMMITEE_REPORTS_CONTEXT = `${FEDERAL_DOC_TROVE_CONTEXT} - commitee reports`;
export const CONGRESSIONAL_PRESS_RELEASES_CONTEXT = `${FEDERAL_DOC_TROVE_CONTEXT} - congressional press releases`;
export const CONGRESSIONAL_RECORD_ITEM_CONTEXT = `${FEDERAL_DOC_TROVE_CONTEXT} - congressional record item`;
export const CONTRACTS_CONTEXT = `${FEDERAL_DOC_TROVE_CONTEXT} - contracts`;
export const CRS_REPORTS_CONTEXT = `${FEDERAL_DOC_TROVE_CONTEXT} - crs reports`;
export const DEFENSE_BUDGETS_CONTEXT = `${FEDERAL_DOC_TROVE_CONTEXT} - defense budgets`;
export const DEAR_COLLEAGUE_LETTER_CONTEXT = `${FEDERAL_DOC_TROVE_CONTEXT} - dear colleague letter`;
export const EXECUTIVE_ORDERS_CONTEXT = `${FEDERAL_DOC_TROVE_CONTEXT} - executive orders`;
export const GAO_REPORTS_CONTEXT = `${FEDERAL_DOC_TROVE_CONTEXT} - gao reports`;
export const GRANTS_CONTEXT = `${FEDERAL_DOC_TROVE_CONTEXT} - grants`;
export const LIVE_HEARINGS_CONTEXT = `${FEDERAL_DOC_TROVE_CONTEXT} - live hearings`;
export const OFICIAL_HEARINGS_CONTEXT = `${FEDERAL_DOC_TROVE_CONTEXT} - oficial hearings`;
export const REGULATIONS_CONTEXT = `${FEDERAL_DOC_TROVE_CONTEXT} - regulations`;
export const SAP_REPORTS_CONTEXT = `${FEDERAL_DOC_TROVE_CONTEXT} - sap reports`;
export const STATE_OF_THE_UNION_ADRESSES_CONTEXT = `${FEDERAL_DOC_TROVE_CONTEXT} - state of the union adresses`;
export const THIRD_PARTY_REPORTS_CONTEXT = `${FEDERAL_DOC_TROVE_CONTEXT} - third party reports`;
export const TREATY_DOCUMENTS_CONTEXT = `${FEDERAL_DOC_TROVE_CONTEXT} - treaty documents`;
export const WHITE_HOUSE_PRESS_BRIEFINGS_CONTEXT = `${FEDERAL_DOC_TROVE_CONTEXT} - white house press briefings`;
