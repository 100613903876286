export const ALL_ACTIVE = 'all_active';
export const ALL_INACTIVE = 'all_inactive';
export const VETOED = 'vetoed';
export const PASSED = 'passed';
export const FAILED = 'failed';
export const LAW = 'law';

export const BILL_STATUS = {
  introduced: 'Introduced',
  engrossed: 'Engrossed',
  enrolled: 'Enrolled',
  passed: 'Passed',
  vetoed: 'Vetoed',
  failed: 'Failed',
  unknown: 'Unknown',
};

export const STATE_BILL_STATUS = {
  all_active: 'All active',
  all_inactive: 'All inactive',
  introduced: 'Introduced',
  engrossed: 'Engrossed',
  enrolled: 'Enrolled',
  passed: 'Passed',
  vetoed: 'Vetoed',
  failed: 'Failed',
  unknown: 'Unknown',
};

export const STATE_BILL_STATUSES = (
  Object.keys(STATE_BILL_STATUS)
    .map(value => ({ value, label: STATE_BILL_STATUS[value] }))
);

export const FEDERAL_BILL_STATUSES = [
  { value: 'all_active', label: 'All active' },
  { value: 'all_inactive', label: 'All inactive' },
  { value: 'introduced', label: 'Introduced' },
  { value: 'referred_to_committee', label: 'Referred to committee' },
  { value: 'reported_out_of_committee', label: 'Reported out of committee' },
  { value: 'passed_chamber', label: 'Passed chamber' },
  { value: 'law', label: 'Law' },
  { value: 'vetoed', label: 'Vetoed' },
];
