import PropTypes from 'prop-types';

export const dragHandlePropsShape = PropTypes.shape({
  tabIndex: PropTypes.number.isRequired,
  role: PropTypes.string,
  'aria-describedby': PropTypes.string,
  'data-rbd-drag-handle-draggable-id': PropTypes.string.isRequired,
  'data-rbd-drag-handle-context-id': PropTypes.string.isRequired,
  draggable: PropTypes.bool.isRequired,
});
