export const INTEL_CONTEXT = 'intel';

export const INTEL_SOCIAL_MEDIA_CONTEXT = `${INTEL_CONTEXT} - social media`;
export const INTEL_FEDERAL_LEGISLATION_CONTEXT = `${INTEL_CONTEXT} - federal legislation`;
export const INTEL_STATE_LEGISLATION_CONTEXT = `${INTEL_CONTEXT} - state legislation`;
export const INTEL_FEDERAL_COMMITTEE_CONTEXT = `${INTEL_CONTEXT} - federal committee`;
export const INTEL_STATE_COMMITTEE_CONTEXT = `${INTEL_CONTEXT} - state committee`;
export const INTEL_STATE_STAFFER_CONTEXT = `${INTEL_CONTEXT} - state staffer`;
export const INTEL_FEDERAL_LEGISLATION_BILLS_CONTEXT = `${INTEL_CONTEXT} - federal legislation - bills`;
export const INTEL_STATE_LEGISLATION_BILLS_CONTEXT = `${INTEL_STATE_LEGISLATION_CONTEXT} - bills`;
export const INTEL_STATE_DOC_TROVE = `${INTEL_CONTEXT} - state doc trove`;
export const INTEL_STATE_DOC_GOVERNOR_PRESS_CONTEXT = `${INTEL_STATE_DOC_TROVE} - governor press releases`;
export const INTEL_STATE_DOC_GOVERNOR_ORDERS_CONTEXT = `${INTEL_STATE_DOC_TROVE} - governor orders`;
export const INTEL_FEDERAL_SAP_REPORT_CONTEXT = `${INTEL_CONTEXT} - federal - sap report`;
export const INTEL_FEDERAL_CRS_REPORT_CONTEXT = `${INTEL_CONTEXT} - federal - crs report`;
export const INTEL_FEDERAL_CONGRESSIONAL_RECORD_CONTEXT = `${INTEL_CONTEXT} - federal - congressional record`;
export const INTEL_FEDERAL_THIRD_PARTY_REPORT_CONTEXT = `${INTEL_CONTEXT} - federal - third party report`;
export const INTEL_FEDERAL_EXECUTIVE_ORDER_CONTEXT = `${INTEL_CONTEXT} - federal - executive order`;
export const INTEL_FEDERAL_STATE_UNION_ADDRESS_CONTEXT = `${INTEL_CONTEXT} - federal - state of the union address`;
export const INTEL_FEDERAL_WH_PRESS_BRIEFING_CONTEXT = `${INTEL_FEDERAL_LEGISLATION_CONTEXT} - white house press briefing`;
export const INTEL_FEDERAL_CBO_COST_ESTIMATE_CONTEXT = `${INTEL_CONTEXT} - federal - cbo cost estimate`;
export const INTEL_FEDERAL_COMMITTEE_REPORT_CONTEXT = `${INTEL_CONTEXT} - federal - committee report`;
export const INTEL_FEDERAL_OFFICIAL_HEARING_TRANSCRIPT_CONTEXT = `${INTEL_CONTEXT} - federal - official hearing transcript`;
export const INTEL_FEDERAL_TREATY_CONTEXT = `${INTEL_CONTEXT} - federal - treaty`;
export const INTEL_FEDERAL_LEGISLATOR_PROFILE_CONTEXT = `${INTEL_FEDERAL_LEGISLATION_CONTEXT} - legislator profile`;
export const INTEL_FEDERAL_GOA_REPORT_CONTEXT = `${INTEL_CONTEXT} - federal - GOA report`;
export const INTEL_STATE_LEGISLATOR_PROFILE_CONTEXT = `${INTEL_STATE_LEGISLATION_CONTEXT} - legislator profile`;
export const INTEL_FEDERAL_COMMITTEE_PROFILE_CONTEXT = `${INTEL_FEDERAL_COMMITTEE_CONTEXT} - committee profile`;
export const INTEL_STATE_COMMITTEE_PROFILE_CONTEXT = `${INTEL_STATE_COMMITTEE_CONTEXT} - committee profile`;
export const INTEL_FEDERAL_BILL_PROFILE_CONTEXT = `${INTEL_FEDERAL_LEGISLATION_BILLS_CONTEXT} - profile`;
export const INTEL_STATE_BILL_PROFILE_CONTEXT = `${INTEL_STATE_LEGISLATION_BILLS_CONTEXT} - profile`;
export const INTEL_STATE_STAFFER_LOOKUP_CONTEXT = `${INTEL_STATE_LEGISLATION_CONTEXT} - staffer lookup`;
export const INTEL_STATE_STAFFER_PROFILE_CONTEXT = `${INTEL_STATE_STAFFER_CONTEXT} - staffer profile`;
export const INTEL_STATE_REGULATION_PROFILE_CONTEXT = `${INTEL_CONTEXT} - state regulations - regulation profile`;
export const INTEL_STATE_REGULATION_LOOKUP_CONTEXT = `${INTEL_CONTEXT} - state regulations - regulation lookup`;
export const INTEL_STATE_EXECUTIVE_PROFILE_CONTEXT = `${INTEL_CONTEXT} - state executive`;
export const LOCAL_CONTEXT = 'local';
