function getBrowser() {
  const isIE = /* @cc_on!@*/false || !!document.documentMode;

  // Edge 20+
  const isEdge = !isIE && !!window.StyleMedia;

  const isIEOrEdgeUntilV44 = !!(window.navigator && window.navigator.msSaveOrOpenBlob);

  return {
    isIE,
    isEdge,
    isIEOrEdgeUntilV44,
  };
}

export default getBrowser;
