import useWindowBreakpoint from 'utils/breakpoints/useWindowBreakpoint';

import { LG_SCREEN, MD_SCREEN, XLG_SCREEN } from './constants';
import {
  medium,
  large,
  xLarge,
} from './settings.scss';

function useMediaQuery() {
  const breakpoint = useWindowBreakpoint();
  const parseToDecimalNumber = number => parseInt(number, 10);

  const mediumParsed = parseToDecimalNumber(medium) || MD_SCREEN;
  const largeParsed = parseToDecimalNumber(large) || LG_SCREEN;
  const xLargeParsed = parseToDecimalNumber(xLarge) || XLG_SCREEN;

  const isMobile = width => width < mediumParsed;
  const isTablet = width => width >= mediumParsed;
  const isDesktop = width => width >= largeParsed;
  const isDesktopLarge = width => width >= xLargeParsed;

  return {
    isMobile: isMobile(breakpoint),
    isTablet: isTablet(breakpoint),
    isDesktop: isDesktop(breakpoint),
    isDesktopLarge: isDesktopLarge(breakpoint),
    width: breakpoint,
  };
}

export default useMediaQuery;
