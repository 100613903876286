import { INTEL_STATE_COMMITTEE_PROFILE_CONTEXT } from '../contexts';
import { track } from '../../';

function trackStateCommitteeProfileEvent(event, data = null) {
  track(event, INTEL_STATE_COMMITTEE_PROFILE_CONTEXT, data);
}

export function trackStateCommitteeNavItemVisited(section) {
  trackStateCommitteeProfileEvent('side nav - item visited', { section });
}

export function trackStateCommitteeBillsFiltered(filter, input) {
  trackStateCommitteeProfileEvent('bills - results filtered', { filter, input });
}

export function trackStateCommitteeBillsSorted(column, direction) {
  trackStateCommitteeProfileEvent('bills - results sorted', { column, direction });
}

export function trackStateCommitteeBillVisited(billId) {
  trackStateCommitteeProfileEvent('bills - bill visited', { bill: billId });
}

export function trackStateCommitteeBillSponsorVisited(legislator) {
  trackStateCommitteeProfileEvent('bills - legislator visited', {
    legislator,
    relationship: 'sponsor',
  });
}

export function trackStateCommitteeBillCosponsorVisited(legislator) {
  trackStateCommitteeProfileEvent('bills - legislator visited', {
    legislator,
    relationship: 'cosponsor',
  });
}

export function trackStateCommitteeBillsPageVisited(page) {
  trackStateCommitteeProfileEvent('bills - page visited', { page });
}
