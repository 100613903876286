export const AGENCY_PRESS_RELEASES_ITEM_TYPE = 'Agency press release';
export const FEDERAL_CONTRACT_ITEM_TYPE = 'Federal contract';
export const FEDERAL_GRANT_ITEM_TYPE = 'Federal grant document';
export const FEDERAL_COMMITTEE_ITEM_TYPE = 'Federal committee';
export const COMMITTEE_REPORTS_ITEM_TYPE = 'Committee report';
export const CONGRESSIONAL_PRESS_RELEASES_ITEM_TYPE = 'Congressional press release';
export const CONGRESSIONAL_RECORD_ITEM_TYPE = 'Congressional record item';
export const SOCIAL_MEDIA_POST_ITEM_TYPE = 'Social media post';
export const DOCTROVE_TREATY_ITEM_TYPE = 'Doctrove treaty';
export const DOCTROVE_GAO_REPORTS_ITEM_TYPE = 'GAO Reports';
export const DOCTROVE_HEARING_TRANSCRIPTS_ITEM_TYPE = 'Hearing transcripts';
export const DOCTROVE_GOVERNOR_PRESS_RELEASE_ITEM_TYPE = 'Governor press release';
export const DOCTROVE_GOVERNOR_ORDER_ITEM_TYPE = 'Governor order';
export const FEDERAL_BILL_ITEM_TYPE = 'Federal bill';
export const FEDERAL_REGULATION_ITEM_TYPE = 'Federal regulation';
export const FEDERAL_LEGISLATIVE_STAFFERS_ITEM_TYPE = 'Federal legislator';
export const FEDERAL_AGENCY_STAFFER_ITEM_TYPE = 'Federal agency staffer';
export const CONGRESSIONAL_STAFFER_ITEM_TYPE = 'Congressional staffer';
export const OFFICIAL_HEARING_TRANSCRIPTS_ITEM_TYPE = 'Official hearing transcript';
export const STATE_BILL_ITEM_TYPE = 'State bill';
export const STATE_REGULATION_ITEM_TYPE = 'State regulation';
export const STATE_REGULATION_AGENCY_ITEM_TYPE = 'State agency regulatory notice';
export const STATE_REGULATION_NOTICE_ITEM_TYPE = 'State regulatory notice';
export const STATE_COMMITTEE_ITEM_TYPE = 'State committee';
export const STATE_LEGISLATIVE_STAFFERS_ITEM_TYPE = 'State legislator';
export const STATE_EXECUTIVE_ITEM_TYPE = 'State executive';
export const STATE_AGENCY_STAFFER_ITEM_TYPE = 'State agency staffer';
export const STATE_STAFFER_ITEM_TYPE = 'State staffer';
export const KEYWORD_ITEM_TYPE = 'Keyword search';
export const NEWS_STORY_ITEM_TYPE = 'News article';
export const NEWSFEED_ITEM_TYPE = 'Newsfeed';
export const LOCAL_EXECUTIVE_ITEM_TYPE = 'Local executive';
export const WORKSPACE_ITEM_TYPE = 'Workspace';
