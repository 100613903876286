export * from './intel/contexts';
export * from './intel/federal_doc_trove/contexts';

export const DASHBOARD_CONTEXT = 'dashboard';
export const DASHBOARD_TRACKING_CENTER_CONTEXT = 'dashboard - tracking center';
export const WORKSPACES_CONTEXT = 'workspaces';

export const ADVOCACY_GRASSROOTS_CONTEXT = 'advocacy - grassroots';
export const ADVOCACY_HILLDAY_IMPORTER_CONTEXT = 'advocacy - hill day - importer';
export const ADVOCACY_HILLDAY_CONTEXT = 'advocacy - hill day';
export const ADVOCACY_HILLDAY_BULK_INVITE_CONTEXT = `${ADVOCACY_HILLDAY_CONTEXT} - bulk invite`;

export const MENU_CONTEXT = 'platform - nav panel';
export const GRASSROOTS_TAKE_ACTION_FLOW_CONTEXT = `${ADVOCACY_GRASSROOTS_CONTEXT} - take action flow`;

export const MONEYBALL_QUICK_SEARCH_CONTEXT = 'moneyball - quick search';
export const MONEYBALL_SMART_SEARCH_CONTEXT = 'moneyball - smart search';
export const MONEYBALL_CONTRIBUTOR_LOOKUP_CONTEXT = 'moneyball - committee - contributor lookup';
export const MONEYBALL_COMMITTEE_CONTEXT = 'moneyball - committee';
export const MONEYBALL_INDIVIDUAL_CONTEXT = 'moneyball - individual';

export const GRM_CONTEXT = 'grm';
export const PUBLIC_FACING_CONTEXT = 'public facing';

export const PLATFORM_HELP_CONTEXT = 'platform - help';

export const STAKEHOLDER_MANAGEMENT_CONTEXT = 'stakeholder management';
export const STAKEHOLDER_CONTEXT = 'stakeholder';
export const STAKEHOLDER_LOOKUP_CONTEXT = `${STAKEHOLDER_CONTEXT} - lookup`;
export const NEWS_CONTEXT = 'news';

export const NAVIGATION_BAR_CONTEXT = 'navigation_bar';

export const GLOBAL_SEARCH_QUICK_SEARCH_CONTEXT = 'global search - quick search';
export const GLOBAL_SEARCH_ADVANCED_SEARCH_CONTEXT = 'global search - advanced search';

export const FEDERAL_AGENCY_STAFFERS_LOOKUP_CONTEXT = 'federal agency staffers - lookup';
