import { track } from './index';

export function trackBillsFiltered(context, filter, input) {
  track('results filtered', context, { filter, input });
}

export function trackBillsSorted(context, column, direction) {
  track('results sorted', context, { column, direction });
}

export function trackBillVisited(context, billId) {
  track('bill visited', context, { bill: billId });
}

export function trackBillSponsorVisited(context, legislator) {
  track('legislator visited', context, {
    legislator,
    relationship: 'sponsor',
  });
}

export function trackBillCosponsorVisited(context, legislator) {
  track('legislator visited', context, {
    legislator,
    relationship: 'cosponsor',
  });
}

export function trackBillsPageVisited(context, page) {
  track('page visited', context, { page });
}
