import { find, map, toString, isEmpty } from 'lodash';

export function isUpdatedItem({ id, recordId, documentType, recordType }) {
  return isEmpty(recordType) || isEmpty(documentType)
    ? toString(recordId) === toString(id)
    : (toString(recordId) === toString(id)) && (recordType === documentType);
}

export function updateDataItem(state, action, keyName) {
  const { [keyName]: data } = state || {};
  const { recordId, recordType, documentType: documentRecordType } = action.payload || {};

  const getUpdatedItem = ({ id, documentType }) => (
    isUpdatedItem({
      id,
      recordId,
      documentType,
      recordType: recordType || documentRecordType,
    }) && action.payload
  );

  return { ...state, [keyName]: map(data, item => ({ ...item, ...getUpdatedItem(item) })) };
}

export function updateDataItemArrayPayload(state, action, keyName) {
  const { [keyName]: data } = state || {};
  const { payload } = action || {};

  if (isEmpty(payload)) {
    return state;
  }

  const getUpdatedItem = ({ id, documentType }) => find(
    payload, ({ recordId, recordType, documentType: documentRecordType }) => (
      isUpdatedItem({
        id,
        recordId,
        documentType,
        recordType: recordType || documentRecordType,
      })
    )
  ) || {};

  return { ...state, [keyName]: map(data, item => ({ ...item, ...getUpdatedItem(item) })) };
}

export function loadingUpdateItem(state, action) {
  const { isLoadingSubscriptions, isLoadingPositions, isLoadingTags } = action || {};

  return {
    ...state,
    ui: {
      ...state.ui,
      isLoadingSubscriptions: isLoadingSubscriptions || false,
      isLoadingPositions: isLoadingPositions || false,
      isLoadingTags: isLoadingTags || false,
    },
  };
}
