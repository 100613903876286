import React, { Component } from 'react';

function fetchAllStates() {
  const data = {
    strategy_slug: 'mappy_state_alpha2',
    required_inputs: JSON.stringify({
      query: 'all',
      page: 1,
      all_states: true,
    }),
  };

  return Promise.resolve($.post('/api_web/suggester', data));
}

function withApiStates(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = { allStates: [], isLoadingAllStates: true };
    }

    componentDidMount() {
      fetchAllStates().then(response => this.setState({
        allStates: response.data,
        isLoadingAllStates: false,
      }));
    }

    render() {
      const { isLoadingAllStates, allStates } = this.state;

      return (
        <WrappedComponent
          isLoadingAllStates={isLoadingAllStates}
          allStates={allStates}
          {...this.props}
        />
      );
    }
  };
}

export default withApiStates;
