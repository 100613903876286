import isNil from 'lodash/isNil';

export const getCurrentUser = () => {
  const { currentUser, companyId } = gon || {};
  const {
    first_name: firstName,
    last_name: lastName,
    user_id: userId,
    email,
    profileImgUrl,
    has_p2a_account: hasP2aAccount,
    ...restProps
  } = currentUser || {};
  const fullName = `${firstName} ${lastName}`;

  return {
    ...restProps,
    firstName,
    lastName,
    email,
    fullName,
    fullNameWithEmail: `${fullName} (${email})`,
    userId,
    profileImgUrl: profileImgUrl || undefined,
    companyId,
    hasP2aAccount,
  };
};

export const isCompanyAdmin = () => {
  const { companyAdmin } = getCurrentUser();

  if (isNil(companyAdmin)) {
    return false;
  }

  return companyAdmin;
};
