const FRESHCHAT_ID = 'fc_frame';
export const offsetClassName = 'actions-offset';

export function addFreshchatClassName(className) {
  const freshchatElem = document.getElementById(FRESHCHAT_ID);
  freshchatElem && freshchatElem.classList.add(className);
}

export function removeFreshchatClassName(className) {
  const freshchatElem = document.getElementById(FRESHCHAT_ID);
  freshchatElem && freshchatElem.classList.remove(className);
}

export function toggleActionsOffset(applyOffset = false) {
  if (applyOffset) {
    addFreshchatClassName(offsetClassName);
  } else {
    removeFreshchatClassName(offsetClassName);
  }
}

export function openFreshChat() {
  const { fcWidget } = window;
  fcWidget && fcWidget.open();
}

export function closeFreshChat() {
  const { fcWidget } = window;
  fcWidget && fcWidget.isOpen() && fcWidget.close();
}
