import isEqual from 'lodash/isEqual';

import { Filter } from 'utils/types/filter';

export const getIsAccordionFilterToOpen = ({
  key,
  searchOptions,
  emptyOptions,
}: {
  key: string;
  searchOptions: { [key: string]: any };
  emptyOptions: { [key: string]: any };
}) => (
  (accordionFilter: Filter): boolean => (
    !accordionFilter.isOpen
    && ((accordionFilter.keyName === key) || (accordionFilter.id === key))
    && !isEqual(searchOptions[key], emptyOptions[key])
  )
);
