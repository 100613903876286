import { INTEL_FEDERAL_BILL_PROFILE_CONTEXT } from '../contexts';

export function trackFederalBillProfileAction(trackingFunction) {
  return (...params) => trackingFunction(
    INTEL_FEDERAL_BILL_PROFILE_CONTEXT,
    ...params
  );
}

/** Roll call votes */
export function trackRollCallVoteVisited(rollCallId) {
  trackFederalBillProfileAction('roll call votes - roll call visited', { rollCall: rollCallId });
}

export function trackRollCallSorted(column, direction) {
  trackFederalBillProfileAction('roll calls votes - results sorted', { column, direction });
}

export function trackRollCallPageVisited(page) {
  trackFederalBillProfileAction('roll call votes - page visited', { page });
}

export function trackVotingRecordIssueVisited(issue, vote) {
  trackFederalBillProfileAction('roll call votes - issue visited', { issue, vote });
}

/** Amendments */
export function trackAmendmentRecordVisited(amendmentId) {
  trackFederalBillProfileAction('amendments - amendment visited', { amendment: amendmentId });
}

export function trackAmendmentSorted(column, direction) {
  trackFederalBillProfileAction('amendments - results sorted', { column, direction });
}

export function trackAmendmentPageVisited(page) {
  trackFederalBillProfileAction('amendments - page visited', { page });
}

/** Lobbying information */
export function trackLobbyingInformationResultsSorted(column, direction) {
  trackFederalBillProfileAction('lobbying information - results sorted', { column, direction });
}

export function trackLobbyingInformationPageVisited(page) {
  trackFederalBillProfileAction('lobbying information - page visited', { page });
}
