export function setCookie(cName, cValue, expiryMins = null) {
  let expires = '';
  if (expiryMins) {
    const d = new Date();
    d.setTime(d.getTime() + (expiryMins * 60 * 1000));
    expires = `;expires=${d.toUTCString()}`;
  }

  document.cookie = `${cName}=${cValue}${expires};path=/`;
}

export function getCookie(cName) {
  const name = `${cName}=`;
  const cookieArray = document.cookie.split(';');
  for (let i = 0, length = cookieArray.length; i < length; i += 1) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return '';
}

export function deleteCookie(name) {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}
