if (window.Sentry) {
  window.Sentry.init({
    dsn: gon.sentryDsnFront,
    environment: gon.env,
  });

  window.Sentry.configureScope((scope) => {
    scope.setUser(gon.currentUser || {});
  });
} else {
  console.error('Sentry initialization failed');
}
