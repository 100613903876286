import * as contexts from 'utils/tracking/contexts';


export default function withTrackingContext(Component) {
  return ({ contextConstantName, ...props }) => {
    let trackingContext = null;
    if (contexts[contextConstantName]) {
      trackingContext = contexts[contextConstantName];
    }

    return Component({ ...props, trackingContext });
  };
}

