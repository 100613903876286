import { size } from 'lodash';

const isPresentDefault = (items, newItem) => items.some(item => item === newItem);

export const split = (array) => {
  const middle = Math.ceil(size(array) / 2);

  return [
    array.slice(0, middle),
    array.slice(middle),
  ];
};

export const pushUniq = (
  array,
  item,
  isPresent = isPresentDefault
) => (
  isPresent(array, item) ? array : [
    ...array,
    item,
  ]
);
