import reportDownloader from 'components/OnePageReport/Creator/reportDownloader';
import { trackReportCreated } from 'utils/tracking';

export function getCompanyLogo(logo) {
  if (logo) {
    return logo;
  }

  const ajax = $.get('/api_web/companies/current');

  return Promise.resolve(ajax).then(({ logo_url: logoUrl }) => (logoUrl || null));
}

export function submitSingleExport(payload) {
  const {
    selectedItems,
    fileExtension,
    isLogoVisible,
    loaders,
    onFileExported,
    limitExceedProps,
    reportSlug,
    sort,
    trackingContext,
    customApiProps,
    documentSummary,
    onNotifySuccess,
    onNotifyFailure,
    fileName,
    visibleColumns,
    enabledColumns,
    defaultColumns,
    perPage,
  } = payload || {};
  const { limit, selectedItemsLength, onLimitExceedError, onLimitExceedInfo } = limitExceedProps || {};
  const { startLoading, stopLoading } = loaders;

  if (selectedItemsLength > limit && onLimitExceedInfo) {
    onLimitExceedInfo(limit);
  }

  if (selectedItemsLength > limit && onLimitExceedError) {
    return onLimitExceedError(limit);
  }

  if (trackingContext) {
    trackReportCreated(trackingContext, reportSlug, fileExtension);
  }

  startLoading();

  return reportDownloader({
    documentIds: selectedItems,
    reportBrand: isLogoVisible,
    fileType: fileExtension,
    reportSlug,
    sort,
    customApiProps,
    documentSummary,
    onNotifySuccess,
    onNotifyFailure,
    fileName,
    visibleColumns,
    enabledColumns,
    defaultColumns,
    perPage,
  }).finally(() => {
    stopLoading();
    onFileExported();
  });
}

export const postJson = (url, data) => $.ajax({
  url,
  type: 'POST',
  contentType: 'application/json',
  data: JSON.stringify(data),
});

export const putJson = (url, data) => $.ajax({
  url,
  type: 'PUT',
  contentType: 'application/json',
  data: JSON.stringify(data),
});

export const mockApiCall = mockResponse => (
  new Promise(resolve => setTimeout(() => resolve(mockResponse), 300))
);
