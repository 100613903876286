export const CHART_COLORS = {
  // Defaults
  FONT_COLOR: '#b0bac9',
  DASH_COLOR: '#dadfe6',
  POINT_BACKGROUND_COLOR: '#ffffff',
  TOOLTIP_BACKGROUND_COLOR: '#26294b',

  // Series
  ALL: '#f8851c',
  CALL: '#8c54ff',
  MAIL: '#2ed599',
  FACEBOOK_POST: '#395699',
  TWITTER_POST: '#1da1f3',
  REGULATION_COMMENT: '#ff0000',

  // Bar Charts
  REPUBLICAN_BACKGROUND_COLOR: '#d86077',
  REPUBLICAN_BORDER_COLOR: '#b74e62',

  DEMOCRATIC_BACKGROUND_COLOR: '#0071ff',
  DEMOCRATIC_BORDER_COLOR: '#005fd7',

  INDEPENDENT_BACKGROUND_COLOR: '#bfbdc5',
  INDEPENDENT_BORDER_COLOR: '#8f9195',

  OTHER_BACKGROUND_COLOR: '#f9ca90',
  OTHER_BORDER_COLOR: '#e8bc85',

  CONGRESS_BACKGROUND_COLOR: '#8c54ff',
  CONGRESS_BORDER_COLOR: '#7f4aea',

  SENATE_BACKGROUND_COLOR: '#2dd599',
  SENATE_BORDER_COLOR: '#26b582',

  DEFAULT_BACKGROUND_COLOR: '#0071ff',
  DEFAULT_BORDER_COLOR: '#005fd7',
};
