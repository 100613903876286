import useLoader from 'utils/useLoader';

function useLoadingRequest({ loadRequest, shouldStopLoading = true }) {
  const { stopLoading, startLoading, isLoading } = useLoader();

  const handleStopLoading = () => shouldStopLoading && stopLoading();

  function handleLoad(...args) {
    startLoading();

    return loadRequest(...args)
      .finally(handleStopLoading);
  }

  return {
    onLoad: handleLoad,
    isLoading,
  };
}

export default useLoadingRequest;
