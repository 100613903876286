import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import { SubmissionError as reduxFormSubmissionError } from 'redux-form';
import jump from 'jump.js';


function stringToValidationError(string, key = 'validation_error') {
  return { [key]: string };
}

export function formatBackEndValidationErrorsRecursive(node = {}) {
  let result = node.responseJSON ? node.responseJSON : node;

  if (isString(result)) {
    result = stringToValidationError(result);
  } else if (isArray(result)) {
    result = stringToValidationError(result.join(', '));
  } else if (isObject(result)) {
    result = Object.keys(result).reduce((acc, key) =>
    ({ ...acc, [key]: formatBackEndValidationErrorsRecursive(result[key]) })
  , {});
  }

  return result;
}

export function SubmissionError({ _error, ...fieldErrors }, callback) {
  if (callback) {
    callback();
  }

  const params = omitBy({
    _error,
    ...formatBackEndValidationErrorsRecursive(fieldErrors),
  }, isUndefined);

  throw new reduxFormSubmissionError(params);
}

export function scrollToFirstError(scrollTo) {
  const elementWithError = document.querySelector('.has-error');

  if (!elementWithError) {
    return;
  }

  const elementToScrollTo = scrollTo && scrollTo(elementWithError) || elementWithError;

  jump(elementToScrollTo);
}
