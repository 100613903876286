import { getIn } from 'formik';
import isEmpty from 'lodash/isEmpty';

/**
 * Get error to a field (Formik usage context)
 * @param fieldName
 * @param formState object { errors, touched }
 */
export default function getError(fieldName, formState) {
  const errors = getIn(formState.errors, fieldName);
  const touched = getIn(formState.touched, fieldName);

  return touched && !isEmpty(errors) ? errors : null;
}
