import React from 'react';

import setDocumentTitle from 'utils/setDocumentTitle';

const renderWithTitle = (Component, title) => (props) => {
  setDocumentTitle(title);

  return <Component {...props} />;
};

export default renderWithTitle;
