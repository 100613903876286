import GlobalNotifier from 'components/GlobalNotifier';

const addNotification = GlobalNotifier.addNotification;

const error = message => addNotification({ message, level: 'error' });

export default class Notifications {
  static notFoundEmails = () => error('The email address(es) for the selected field(s) were not found.');
  static limitedEmails = () => error('Your browser won’t let us append this many email addresses into one link.');
  static exportEmailFailed = () => error('An error occurred. Please try again.');
}
