import { INTEL_STATE_STAFFER_PROFILE_CONTEXT } from '../contexts';
import { track } from '../../';

function trackStateStafferProfileEvent(event, data = null) {
  track(event, INTEL_STATE_STAFFER_PROFILE_CONTEXT, data);
}

export function trackStateStafferProfileLegislatorVisited() {
  trackStateStafferProfileEvent('state staffers - profile - legislator visited');
}

export function trackStateStafferProfileCommitteeVisited() {
  trackStateStafferProfileEvent('state staffers - profile - committee visited');
}

export function trackStateStafferProfileColleagueEmailed() {
  trackStateStafferProfileEvent('state staffers - profile - colleague emailed');
}

export function trackStateStafferProfileColleagueVisited() {
  trackStateStafferProfileEvent('state staffers - profile - colleague visited');
}
