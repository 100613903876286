import { track } from '../';
import { INTEL_FEDERAL_LEGISLATION_CONTEXT } from './contexts';


export function trackFederalLegislationEvent(eventName, data = null) {
  track(eventName, INTEL_FEDERAL_LEGISLATION_CONTEXT, data);
}

export function trackFederalLegislationBillsSearched() {
  trackFederalLegislationEvent('bills - searched');
}

export function trackFederalLegislationBillsResultVisited() {
  trackFederalLegislationEvent('bills - result visited');
}

export function trackHearingVisited({ menu }) {
  trackFederalLegislationEvent('hearings calendar - hearing visited', { menu });
}

export function trackHearingAddedToCalendar() {
  trackFederalLegislationEvent('hearings calendar - hearing added to calendar');
}

export function trackHearingExported({ fileType, exportType }) {
  trackFederalLegislationEvent('hearings calendar - hearing exported', { fileType, exportType });
}

export function trackHearingShared({ type }) {
  trackFederalLegislationEvent('hearings calendar - hearing shared', { type });
}

export function trackFederalAllHearingsSelected() {
  trackFederalLegislationEvent('hearings calendar - all hearings selected');
}

export function trackFederalCommitteesFiltered() {
  trackFederalLegislationEvent('hearings calendar - committees filtered');
}

export function trackMonthToggled() {
  trackFederalLegislationEvent('hearings calendar - month toggled');
}

export function trackHearingsCommitteeVisited() {
  trackFederalLegislationEvent('hearings calendar - committee visited');
}

export function trackDateChosen() {
  trackFederalLegislationEvent('hearings calendar - date chosen');
}

export function trackLegislatorsResultSearched() {
  trackFederalLegislationEvent('legislators - legislators searched');
}

export function trackLegislatorsResultVisited() {
  trackFederalLegislationEvent('legislators - result viewed');
}

export function trackLegislatorsResultsExported() {
  trackFederalLegislationEvent('legislators - results exported');
}
