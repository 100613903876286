import { track } from './';
import { ADVOCACY_GRASSROOTS_CONTEXT, GRASSROOTS_TAKE_ACTION_FLOW_CONTEXT } from './contexts';


function trackGrassrootsEvent(eventName, data = {}) {
  track(eventName, ADVOCACY_GRASSROOTS_CONTEXT, data);
}

function trackTakeActionFlowEvent(eventName, data = null) {
  track(eventName, GRASSROOTS_TAKE_ACTION_FLOW_CONTEXT, data);
}

export function trackSaveStateRecipients({ campaignType }) {
  trackGrassrootsEvent('state recipients saved', { campaignType });
}

export function trackSaveFederalRecipients({ campaignType }) {
  trackGrassrootsEvent('federal recipients saved', { campaignType });
}

export function trackCampaignManagerVisited({ menu, campaignType }) {
  trackGrassrootsEvent('campaign manager visited', { menu, campaignType });
}

export function trackDistributionManagerVisited({ menu, campaignType }) {
  trackGrassrootsEvent('distribution manager visited', { menu, campaignType });
}

export function trackReportingCenterVisited({ menu, campaignType }) {
  trackGrassrootsEvent('reporting center visited', { menu, campaignType });
}

export function trackCampaignSettingsVisited({ menu, campaignType }) {
  trackGrassrootsEvent('campaign settings visited', { menu, campaignType });
}

export function trackPreviewCampaign({ menu, campaignType }) {
  trackGrassrootsEvent('campaign previewed', { menu, campaignType });
}

export function trackCampaignDetailsVisited({ campaignType }) {
  trackGrassrootsEvent('campaign details visited', { campaignType });
}

export function trackFederalRecipientsVisited({ campaignType }) {
  trackGrassrootsEvent('federal recipients visited', { campaignType });
}

export function trackStateRecipientsVisited({ campaignType }) {
  trackGrassrootsEvent('state recipients visited', { campaignType });
}

export function trackCustomRecipientsVisited({ campaignType }) {
  trackGrassrootsEvent('custom recipients visited', { campaignType });
}

export function trackComposeMessagesVisited({ campaignType }) {
  trackGrassrootsEvent('compose messages visited', { campaignType });
}

export function trackAddThankYouVisited({ campaignType }) {
  trackGrassrootsEvent('add thank you visited', { campaignType });
}

export function trackAddRegulationVisited({ campaignType }) {
  trackGrassrootsEvent('add regulation visited', { campaignType });
}

export function trackComposeCommentVisited({ campaignType }) {
  trackGrassrootsEvent('compose comment visited', { campaignType });
}

export function trackCampaignDetailSave({ campaignType }) {
  trackGrassrootsEvent('campaign details saved', { campaignType });
}

export function trackNewCampaignCreated({ campaignType }) {
  trackGrassrootsEvent('new campaign initiated', { campaignType });
}

export function trackLogoUploaded({ campaignType }) {
  trackGrassrootsEvent('logo uploaded', { campaignType });
}

export function trackSaveSettings({ campaignType }) {
  trackGrassrootsEvent('settings saved', { campaignType });
}

export function trackSaveDistribution({ campaignType }) {
  trackGrassrootsEvent('distribution saved', { campaignType });
}

export function trackExportReport({ campaignType, format }) {
  trackGrassrootsEvent('report exported', { campaignType, format });
}

export function trackSuportContacted() {
  trackGrassrootsEvent('custom recipients - support contacted');
}

export function trackActionFlowContactInfoSubmitted(campaignType) {
  trackTakeActionFlowEvent('contact info submitted', { campaignType });
}

export function trackActionFlowMessageSent(campaignType) {
  trackTakeActionFlowEvent('messages sent', { campaignType });
}

export function trackActionFlowTwitterPostVisited(campaignType) {
  trackTakeActionFlowEvent('twitter post visited', { campaignType });
}

export function trackActionFlowFacebookPostVisited(campaignType) {
  trackTakeActionFlowEvent('facebook post visited', { campaignType });
}

export function trackActionFlowPhoneConfirmed(campaignType) {
  trackTakeActionFlowEvent('phone confirmed', { campaignType });
}

export function trackActionFlowCallInitiated(campaignType) {
  trackTakeActionFlowEvent('call initiated', { campaignType });
}

export function trackActionFlowCommentSubmitted(campaignType) {
  trackTakeActionFlowEvent('comment submitted', { campaignType });
}
