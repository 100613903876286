export const DOC_TREATY = {
  name: 'Treaty document',
  apiUrl: 'doctrove_treaty_document',
  tagsApiUrl: 'doctrove_treaty',
  myFeedApiUrl: 'doctrove_treaty',
};
export const DOC_AGENCY_PRESS_RELEASE = {
  name: 'Agency press release',
  apiUrl: 'doctrove_agency_press_release',
};
export const DOC_CBO = {
  name: 'CBO cost estimate',
  apiUrl: 'doctrove_cbo_report',
  tagsApiUrl: 'doctrove_cbo',
  myFeedApiUrl: 'doctrove_cbo',
};
export const DOC_COMMITTEE_REPORT = {
  name: 'Committee report',
  apiUrl: 'doctrove_committee_report',
  tagsApiUrl: 'doctrove_committee_report',
  myFeedApiUrl: 'doctrove_committee_report',
};
export const DOC_CONGRESSIONAL_PRESS_RELEASE = {
  name: 'Congressional press release',
  apiUrl: 'doctrove_congressional_press_release',
};
export const DOC_CONGRESSIONAL_RECORD = {
  name: 'Congressional record',
  apiUrl: 'doctrove_congressional_record',
  tagsApiUrl: 'congressional_record',
  myFeedApiUrl: 'congressional_record',
};
export const DOC_DEAR_COLLEAGUE_LETTER = {
  name: 'Dear colleague letter',
  apiUrl: 'doctrove_dear_colleague_letter',
};
export const DOC_GAO_REPORT = {
  name: 'GAO report',
  apiUrl: 'doctrove_gao_report',
  tagsApiUrl: 'doctrove_gao',
  myFeedApiUrl: 'doctrove_gao',
};
export const DOC_GOV_ORDER = {
  name: 'Governor order',
  apiUrl: 'doctrove_governor_order',
};
export const DOC_GOV_PRESS_RELEASE = {
  name: 'Governor press release',
  apiUrl: 'doctrove_governor_press_release',
};
export const DOC_GRANT = {
  name: 'Grant',
  apiUrl: 'doctrove_grant',
};
export const DOC_OFFICIAL_HEARING_TRANSCRIPT = {
  name: 'Official hearing transcript',
  apiUrl: 'doctrove_hearing_transcript',
  tagsApiUrl: 'doctrove_hearing_transcript',
  myFeedApiUrl: 'doctrove_hearing_transcript',
};
export const DOC_WH_PRESS_BRIEFING = {
  name: 'White House press briefing',
  apiUrl: 'doctrove_press_briefing',
  tagsApiUrl: 'doctrove_press_briefing',
  myFeedApiUrl: 'doctrove_press_briefing',
};
export const DOC_THIRD_PARTY_REPORT = {
  name: 'Third party report',
  apiUrl: 'doctrove_third_party_report',
  tagsApiUrl: 'doctrove_third_party_report',
  myFeedApiUrl: 'doctrove_third_party_report',
};
export const DOC_CRS_REPORT = {
  name: 'CRS report',
  apiUrl: 'doctrove_crs_report',
  tagsApiUrl: 'doctrove_crs',
  myFeedApiUrl: 'doctrove_crs',
};
export const DOC_EXECUTIVE_ORDER = {
  name: 'Executive order',
  apiUrl: 'doctrove_executive_report',
  tagsApiUrl: 'doctrove_executive_order',
  myFeedApiUrl: 'doctrove_executive_order',
};
export const DOC_UNION_STATEMENT = {
  name: 'State of the Union address',
  apiUrl: 'doctrove_union_statement',
  tagsApiUrl: 'doctrove_union_statement',
  myFeedApiUrl: 'doctrove_union_statement',
};
export const DOC_LOCAL = {
  name: 'Local intelligence',
  apiUrl: 'town_intel',
};
export const DOC_CONTRACT = {
  name: 'Contract',
  apiUrl: 'doctrove_fbo',
};
export const DOC_FARA = {
  name: 'FARA',
  apiUrl: 'doctrove_fara',
};
export const DOC_SAP = {
  name: 'SAP report',
  apiUrl: 'doctrove_sap_report',
  tagsApiUrl: 'doctrove_sap',
  myFeedApiUrl: 'doctrove_sap',
};
export const DOC_LIVE_HEARING_TRANSCRIPT = {
  name: 'Live hearing transcript',
  apiUrl: 'doctrove_live_hearing_transcript',
};
