export const DEFAULT_CALENDAR_TIME_ZONE = 'America/New_York';
export const EASTERN_TIME_ABBREVIATION = 'ET';
export const CALENDAR_DATE_FORMAT = 'YYYY-MM-DD';
export const SHORT_CALENDAR_DATE_FORMAT = 'YYYY-MM';
export const GLOBAL_DATE_FORMAT = 'MM/DD/YYYY';
export const SHORT_DATE_FORMAT = 'MMMM, YYYY';
export const SHORT_TIME_FORMAT = 'h:mm A';
export const SHORT_RANGE_DATE_FORMAT = 'MMM. YYYY';
export const SHORT_RANGE_DATE_PICKER_FORMAT = 'MMM. yyyy';
export const FULL_DATE_FORMAT = 'dddd, MMMM D, YYYY h:mma';
export const TASK_FULL_DATE_FORMAT = 'dddd, MMMM D, YYYY, [at] h:mma [ET]';
export const DAY_DATE_FORMAT = 'dddd, MMMM D';
export const DAY_YEAR_DATE_FORMAT = 'dddd, MMMM D, YYYY';
export const MONTH_SHORT_DAY_YEAR_FORMAT = 'MMM D, YYYY';
export const MONTH_DAY_YEAR_DATE_FORMAT = 'MMMM D, YYYY';
export const YEAR_DATE_FORMAT = 'YYYY';
export const EXPORT_AT_FORMAT = 'MM/DD/YYYY [at] hh:mm A';
export const LAST_SYNC_AT_FORMAT = 'MM/DD/YYYY [at] HH:mm:ss A';
