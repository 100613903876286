import { isEmpty, size, map, filter } from 'lodash';

import getBrowser from 'utils/breakpoints/getBrowser';
import { joinCompact } from 'utils/string';

import Notifications from './Notifications';

export function getEmailsLink({ withFilter = true, selectedItems = [], data = [], trackResultEmailed }) {
  function mapEmails({ email } = {}) {
    return email;
  }

  function unique(value, index, self) {
    return self.indexOf(value) === index;
  }

  function filterEmails(emails) {
    return filter(map(emails, mapEmails), unique);
  }

  const { isIE } = getBrowser();
  const emails = withFilter
    ? filterEmails(selectedItems)
    : filterEmails(data);

  /** IE limit */
  const urlCharactersLimit = 2048;

  if (isEmpty(emails)) {
    return Notifications.exportEmailFailed();
  }

  const emailsFormat = joinCompact(emails, ';');
  const emailsOverLimit = size(emailsFormat) > urlCharactersLimit;

  if (isIE && emailsOverLimit) {
    return Notifications.limitedEmails();
  }

  trackResultEmailed && trackResultEmailed();

  return window.open(`mailto:?bcc=${emailsFormat}`, '_blank');
}
