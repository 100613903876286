import React from 'react';
import anchorme from 'anchorme';

const textToHyperLink = ({ text, ...restProps }) => {
  const options = {
    attributes: {
      target: '_blank',
      ...restProps,
    },
  };

  const html = anchorme({
    input: text,
    options,
  });

  return (
    <span dangerouslySetInnerHTML={{ __html: html }} />
  );
};

export default textToHyperLink;
