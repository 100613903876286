const HIGHLIGHT_OPEN_TAG = '<em>';
const HIGHLIGHT_CLOSE_TAG = '</em>';
const HIGHLIGHT_SURROUND_ID = 'EM';
const HIGHLIGHT_BLOCK_IDENTIFIER = 'HIGHLIGHT_THIS_BLOCK';

export function splitHighlightText(text = '') {
  const textReplaced = text
    .replace(new RegExp(HIGHLIGHT_OPEN_TAG, 'g'), HIGHLIGHT_SURROUND_ID)
    .replace(
      new RegExp(HIGHLIGHT_CLOSE_TAG, 'g'),
      `${HIGHLIGHT_BLOCK_IDENTIFIER}${HIGHLIGHT_SURROUND_ID}`
    );

  const textPieces = textReplaced.split(HIGHLIGHT_SURROUND_ID);

  return textPieces.map(textPiece => ({
    isHighlight: textPiece.includes(HIGHLIGHT_BLOCK_IDENTIFIER),
    textContent: textPiece.replace(HIGHLIGHT_BLOCK_IDENTIFIER, ''),
  }));
}

export function isKeywordText(text = '') {
  return text.includes(HIGHLIGHT_OPEN_TAG);
}
