import getBrowser from 'utils/breakpoints/getBrowser';
import { isIOS } from 'utils/device';
import { PDF_ID } from 'utils/constants/export';

export const OCTET_BLOB_TYPE = 'octet/stream';
export const PDF_BLOB_TYPE = 'application/pdf';

export function getBlob(data, type = OCTET_BLOB_TYPE) {
  return new Blob([data], { type });
}

function downloadWithClick(url, fileName) {
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);

  a.click();
}

function downloadBlobIOS(blob, fileName) {
  const reader = new FileReader();

  reader.onload = function onLoad() {
    downloadWithClick(reader.result, fileName);
  };

  reader.readAsDataURL(blob);
}

function isPDF(blob) {
  return blob.type === PDF_BLOB_TYPE;
}

export function downloadBlob(blob, fileName) {
  const { isIEOrEdgeUntilV44 } = getBrowser();

  if (isIEOrEdgeUntilV44) {
    window.navigator.msSaveOrOpenBlob(blob, fileName);
    return;
  }

  if (isIOS && !isPDF(blob)) {
    // Files that are able to be viewed in the browser (ex. pdf, images) should not use FileReader.
    // FileReader.readAsDataURL will result in a base64 file
    // and try to open the link will trigger the following error:
    // Window is not allowed to navigate Top-frame navigations to data URLs
    downloadBlobIOS(blob, fileName);
    return;
  }

  const url = window.URL.createObjectURL(blob);
  downloadWithClick(url, fileName);
  window.URL.revokeObjectURL(url);
}

function getBlobType(fileType) {
  if (fileType === PDF_ID) return { type: PDF_BLOB_TYPE };

  return { type: OCTET_BLOB_TYPE };
}

export function exportBlob(data, fileName, fileType) {
  const blobType = getBlobType(fileType);

  const blob = (data instanceof Blob)
    ? data
    : getBlob(data, blobType);

  downloadBlob(blob, fileName);
}
