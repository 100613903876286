import { track } from '../';
import { INTEL_STATE_LEGISLATION_CONTEXT } from './contexts';

function createStateLegislationEvent(eventName) {
  return (data) => {
    track(eventName, INTEL_STATE_LEGISLATION_CONTEXT, data);
  };
}

export const trackStateLegislationBillsResultVisited =
  createStateLegislationEvent('bills - result visited');

export const trackStateLegislationBillsSearched =
  createStateLegislationEvent('bills - searched');

export const trackStateLegislatorsSearched =
  createStateLegislationEvent('legislators - legislators searched');

export const trackStateLegislatorsResultsExported =
  createStateLegislationEvent('legislators - results exported');

export const trackStateLegislatorsResultViewed =
  createStateLegislationEvent('legislators - result viewed');

export const trackStateAllHearingsSelected =
  createStateLegislationEvent('hearings calendar - all hearings selected');

export const trackStateCommitteesFiltered =
  createStateLegislationEvent('hearings calendar - committees filtered');
