import { track } from './';
import {
  MONEYBALL_QUICK_SEARCH_CONTEXT,
  MONEYBALL_SMART_SEARCH_CONTEXT,
  MONEYBALL_CONTRIBUTOR_LOOKUP_CONTEXT,
  MONEYBALL_COMMITTEE_CONTEXT,
  MONEYBALL_INDIVIDUAL_CONTEXT,
} from './contexts';

function getInputValue(inputId) {
  const input = document.getElementById(inputId);
  return input && input.value;
}

function trackMoneyBallQuickSearchEvents(eventName, data = null) {
  track(eventName, MONEYBALL_QUICK_SEARCH_CONTEXT, data);
}

function trackMoneyBallSmartSearchEvents(eventName, data = null) {
  track(eventName, MONEYBALL_SMART_SEARCH_CONTEXT, data);
}

function trackLookupEvent(eventName, data = null) {
  track(eventName, MONEYBALL_CONTRIBUTOR_LOOKUP_CONTEXT, data);
}

function trackMoneyballIndividualContext(eventName, data = null) {
  track(eventName, MONEYBALL_INDIVIDUAL_CONTEXT, data);
}

export function trackPacVisited() {
  trackMoneyBallQuickSearchEvents('pac visited');
}

export function trackPresidentialVisited() {
  trackMoneyBallQuickSearchEvents('presidential visited');
}

export function trackComitteeVisited() {
  trackMoneyBallQuickSearchEvents('committee visited');
}

export function trackCandidateVisited() {
  trackMoneyBallQuickSearchEvents('candidate visited');
}

export function trackContributorVisited() {
  trackMoneyBallQuickSearchEvents('contributor visited');
}

export function trackContributorLookupSearched({ inputId }) {
  trackLookupEvent('searched', { keywords: getInputValue(inputId) });
}

export function trackCombinedResultsVisited() {
  trackLookupEvent('combined results visited');
}

export function trackCommitteeContributorLookupResultVisited() {
  trackLookupEvent('result visited');
}

export function trackDonorScoutPacsSearched() {
  track('donor scout pacs - searched', MONEYBALL_COMMITTEE_CONTEXT);
}

export function trackDonorScoutPacsExported() {
  track('donor scout pacs - exported', MONEYBALL_COMMITTEE_CONTEXT);
}

export function trackDonorScoutPresidentsSearched() {
  track('donor scout presidents - searched', MONEYBALL_COMMITTEE_CONTEXT);
}

export function trackDonorScoutPresidentsExported() {
  track('donor scout presidents - exported', MONEYBALL_COMMITTEE_CONTEXT);
}

export function trackDonorScoutCongressionalSearched() {
  track('donor scout congressional - searched', MONEYBALL_COMMITTEE_CONTEXT);
}

export function trackDonorScoutCongressionalExported() {
  track('donor scout congressional - exported', MONEYBALL_COMMITTEE_CONTEXT);
}

export function trackReceivedIndividualContributionsExported() {
  track('contributor - received individual contributions exported', MONEYBALL_COMMITTEE_CONTEXT);
}

export function trackReceivedCommitteeContributionsExported() {
  track('contributor - received committee contributions exported', MONEYBALL_COMMITTEE_CONTEXT);
}

export function trackDonatedContributionsExported() {
  track('contributor - donated contributions exported', MONEYBALL_COMMITTEE_CONTEXT);
}

export function trackPacRecipientReported() {
  track('pac recipient - exported', MONEYBALL_COMMITTEE_CONTEXT);
}

export function trackIndividualDonorScoutPresidentsSearched() {
  trackMoneyballIndividualContext('donor scout presidents - searched');
}

export function trackIndividualDonorScoutPresidentsExported() {
  trackMoneyballIndividualContext('donor scout presidents - exported');
}

export function trackIndividualDonorScoutCongressionalSearched() {
  trackMoneyballIndividualContext('donor scout congressional - searched');
}

export function trackIndividualDonorScoutCongressionalExported() {
  trackMoneyballIndividualContext('donor scout congressional - exported');
}

export function trackIndividualDonorScoutPacsSearched() {
  trackMoneyballIndividualContext('donor scout pacs - searched');
}

export function trackIndividualDonorScoutPacsExported() {
  trackMoneyballIndividualContext('donor scout pacs - exported');
}

export function trackIndividualDetailedSearchSearched() {
  trackMoneyballIndividualContext('detailed search - searched');
}

export function trackIndividualDetailedSearchExported() {
  trackMoneyballIndividualContext('detailed search - exported');
}

export function trackIndividualContributorContributionsExported() {
  trackMoneyballIndividualContext('contributor - contributions exported');
}

export function trackIndividualContributorOnePageExported() {
  trackMoneyballIndividualContext('contributor - one page exported');
}

export function trackIndividualContributorRecipientVisited() {
  trackMoneyballIndividualContext('contributor - recipient visited');
}

export function trackIndividualContributorLookupSearched({ inputId }) {
  trackMoneyballIndividualContext('contributor lookup - searched', { keywords: getInputValue(inputId) });
}

export function trackIndividualContributorCombinedResultsVisited() {
  trackMoneyballIndividualContext('contributor lookup - combined results visited');
}

export function trackIndividualContributorResultVisited() {
  trackMoneyballIndividualContext('contributor lookup - result visited');
}

export function trackCommitteeContributorRecipientVisited() {
  track('contributor - recipient visited', MONEYBALL_COMMITTEE_CONTEXT);
}

export function trackCommitteeVisited() {
  track('contributor - contributor visited', MONEYBALL_COMMITTEE_CONTEXT);
}

export function trackMoneyBallSmartSearchCreate() {
  trackMoneyBallSmartSearchEvents('create new search');
}

export function trackMoneyBallSmartSearchUpdate() {
  trackMoneyBallSmartSearchEvents('update search');
}

export function trackMoneyBallSmartSearchExport() {
  trackMoneyBallSmartSearchEvents('export results');
}
