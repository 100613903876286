import { reduce, camelCase, isObject, isArray } from 'lodash';

export const objectMap = (obj, fn) => (
  Object.keys(obj).reduce((acc, key) => ({
    ...acc,
    [key]: fn(obj[key]),
  }), {})
);

export const camelizeKeys = (object) => {
  if (!isObject(object)) return object;

  return reduce(object, (camelizedObject, value, key) => {
    let transformedValue = value;

    if (isArray(value)) {
      transformedValue = value.map(camelizeKeys);
    } else if (isObject(value)) {
      transformedValue = camelizeKeys(value);
    }

    return { ...camelizedObject, [camelCase(key)]: transformedValue };
  }, {});
};
