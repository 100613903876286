import PropTypes from 'prop-types';

export const paginationShape = PropTypes.shape({
  current: PropTypes.number,
  isLastPage: PropTypes.bool,
  nextPage: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  perPage: PropTypes.number,
  total: PropTypes.number,
  totalCount: PropTypes.number,
});
