import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

function mapStateToProps(statePath) {
  return state => statePath.reduce((store, key) => (store[key] || {}), state);
}

function mapDispatchToProps(actions) {
  return dispatch => bindActionCreators(actions, dispatch);
}

export default function reduxConnect(statePath, actions, options = {}) {
  const {
    withRef = false,
    mergeProps = null,
  } = options;

  return Component => connect(
    mapStateToProps(statePath.split('.')),
    mapDispatchToProps(actions),
    mergeProps,
    { withRef }
  )(Component);
}
