import * as Export from './export';
import SORT_DIRECTIONS from './sortDirections';
import { PHONE_REGEXP, TWITTER_USERNAME_REGEXP, FACEBOOK_PROFILE_URL_REGEXP } from './regexps';
import PARTIES from './parties';
import { COMMITTEE_TYPE, LEGISLATOR_TYPE } from './stafferTypes';
import { EMPTY_VALUE } from './emptyData';
import * as typeOptions from './typeOptions';
import { POST_REQUEST, GET_REQUEST, PUT_REQUEST } from './api';
import CHAMBERS from './chambers';

const BASE_URL = gon && gon.baseUrl || 'https://app.govpredict.com';
const GOOGLE_MAPS_QUERY_URL = 'https://maps.google.com/?q=';
const P2A_LOGIN_STAGING_URL = 'https://adminstage.phone2action.com';
const P2A_LOGIN_PRODUCTION_URL = 'https://admin.phone2action.com';
const US_COORDINATES = { latitude: 37.0902, longitude: -95.7129 };
const DEFAULT_LOGO_URL = '/images/intelligence-logo.png';
const DEFAULT_IMAGE_PLACEHOLDER = '/images/missing_image.svg';
const TOOLTIP_OFFSET = [0, 10];
const PRODUCT_BAR_HEIGHT = 30;

export {
  Export,
  SORT_DIRECTIONS,
  PHONE_REGEXP,
  PARTIES,
  TWITTER_USERNAME_REGEXP,
  FACEBOOK_PROFILE_URL_REGEXP,
  COMMITTEE_TYPE,
  LEGISLATOR_TYPE,
  EMPTY_VALUE,
  typeOptions,
  BASE_URL,
  P2A_LOGIN_PRODUCTION_URL,
  P2A_LOGIN_STAGING_URL,
  GOOGLE_MAPS_QUERY_URL,
  US_COORDINATES,
  DEFAULT_LOGO_URL,
  DEFAULT_IMAGE_PLACEHOLDER,
  POST_REQUEST,
  GET_REQUEST,
  PUT_REQUEST,
  TOOLTIP_OFFSET,
  CHAMBERS,
  PRODUCT_BAR_HEIGHT,
};
