// Alternative to the .closest DOM API (unsupported by IE)

const closest = (element, matchFunction) => (
  element && (matchFunction(element) ? element : closest(element.parentNode, matchFunction))
);

const matchTag = tagName => element => element.tagName.toLowerCase() === tagName;

export const closestByTag = (element, tagName) => (
  closest(element, matchTag(tagName))
);

export default closest;
