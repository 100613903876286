import { track } from './index';
import { NEWS_CONTEXT } from './contexts';

export function trackNewsEvent(eventName, data = null) {
  track(eventName, NEWS_CONTEXT, data);
}

export function trackNewsOpened() {
  trackNewsEvent('page opened');
}

export function trackNewsFeedCreated() {
  trackNewsEvent('feed created');
}

export function trackNewsFeedUpdated() {
  trackNewsEvent('feed updated');
}

export function trackNewsFeedRemoved() {
  trackNewsEvent('feed removed');
}

export function trackNewsShared(shareType, feedType) {
  trackNewsEvent('story shared', { shareType, feedType });
}

export function trackNewsExported(fileType, feedType) {
  trackNewsEvent('stories exported', { fileType, feedType });
}

export function trackNewsSubscribed(feedId, feedName, frequency) {
  trackNewsEvent('feed subscribed', { feedId, feedName, frequency });
}
