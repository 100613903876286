import { CHART_COLORS } from './colors';

export const BASE_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  legend: { display: false },
  plugins: {
    datalabels: { display: false },
  },
};

export const BASE_TICK = {
  padding: 20,
  fontFamily: 'Roboto',
  fontSize: 15,
  fontColor: CHART_COLORS.FONT_COLOR,
};

export const BASE_GRID_LINES = {
  zeroLineColor: CHART_COLORS.DASH_COLOR,
  color: CHART_COLORS.DASH_COLOR,
  borderDash: [2, 2],
  tickMarkLength: 0,
  lineWidth: 1,
};

const chartBorder = {
  ticks: {
    display: false,
  },
  gridLines: {
    display: false,
    drawTicks: false,
  },
};

export const BASE_BORDER_TOP_GRID = {
  ...chartBorder,
  position: 'top',
};

export const BASE_BORDER_RIGHT_GRID = {
  ...chartBorder,
  position: 'right',
};

export const BASE_BORDER_LEFT_GRID = {
  ...chartBorder,
  position: 'left',
};

export const BASE_TOOLTIP = {
  backgroundColor: CHART_COLORS.TOOLTIP_BACKGROUND_COLOR,
  opacity: 1,
  xPadding: 30,
  yPadding: 10,
  caretSize: 10,
  displayColors: false,
  bodyFontSize: 13,
  bodyFontFamily: 'Roboto',
};
