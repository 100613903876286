import { track } from 'utils/tracking';
import { STAKEHOLDER_LOOKUP_CONTEXT, STAKEHOLDER_MANAGEMENT_CONTEXT } from 'utils/tracking/contexts';

export function trackStakeholderLookupEvent(eventName, data = {}) {
  track(`stakeholders - ${eventName}`, STAKEHOLDER_LOOKUP_CONTEXT, data);
}

export function trackStakeholderManagementEvent(eventName, data = {}) {
  track(eventName, STAKEHOLDER_MANAGEMENT_CONTEXT, data);
}
