import { track } from '../';
import { INTEL_FEDERAL_LEGISLATION_CONTEXT } from './contexts';

function trackRolodexEvent(eventName, data = {}) {
  track(eventName, INTEL_FEDERAL_LEGISLATION_CONTEXT, data);
}

export function trackFederalAgencyContactsSearched() {
  trackRolodexEvent('agency staffers - searched');
}

export function trackFederalAgencyContactsResultsExported() {
  trackRolodexEvent('agency staffers - results exported');
}

export function trackFederalAgencyContactsResultsEmailed() {
  trackRolodexEvent('agency staffers - results emailed');
}

export function trackFederalAgencyContactsResultExpanded() {
  trackRolodexEvent('agency staffers - result expanded');
}

export function trackFederalAgencyContactsSocialMediaVisited({ network }) {
  trackRolodexEvent('agency staffers - social media visited', { network });
}

export function trackStafferMonitoringSubscriptionCreated({ type, inputId }) {
  const frequency = document.getElementById(inputId).value;
  trackRolodexEvent('staffer monitoring - subscription created', { type, frequency });
}

export function trackMonitoringNewSubscriptionClicked() {
  trackRolodexEvent('staffer monitoring - new subscription clicked');
}

export function trackStaffersSaveSearchDeleted() {
  trackRolodexEvent('congressional staffers - save search deleted');
}

export function trackStaffersSearchSaved() {
  trackRolodexEvent('congressional staffers - search saved');
}

export function trackStaffersSearched() {
  trackRolodexEvent('congressional staffers - searched');
}

export function trackStaffersAllResultsEmailed() {
  trackRolodexEvent('congressional staffers - all results emailed');
}

export function trackStaffersResultsExported() {
  trackRolodexEvent('congressional staffers - results exported');
}

export function trackStaffersResultVisited() {
  trackRolodexEvent('congressional staffers - result visited');
}

export function trackStaffersResultEmailed() {
  trackRolodexEvent('congressional staffers - result emailed');
}

export function trackStaffersVisited(isCommittee = false) {
  const itemValue = isCommittee ? 'committee' : 'legislator';
  trackRolodexEvent(`staffers - ${itemValue} visited`);
}

export function trackStafferProfileLegislatorVisited() {
  trackRolodexEvent('congressional staffers - profile - legislator visited');
}

export function trackStafferProfileCommitteeVisited() {
  trackRolodexEvent('congressional staffers - profile - committee visited');
}

export function trackStafferProfileOfficeHomepageVisited() {
  trackRolodexEvent('congressional staffers - profile - office homepage visited');
}

export function trackStafferProfileColleagueEmailed() {
  trackRolodexEvent('congressional staffers - profile - colleague emailed');
}

export function trackStafferProfileSocialMediaVisited({ network }) {
  trackRolodexEvent('congressional staffers - profile - social media visited', { network });
}

export function trackStafferProfileColleagueVisited() {
  trackRolodexEvent('congressional staffers - profile - colleague visited');
}

export function trackStafferProfileCongressionalEmploymentToggled() {
  trackRolodexEvent('congressional staffers - profile - congressional employment toggled');
}
