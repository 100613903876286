import { INTEL_FEDERAL_LEGISLATOR_PROFILE_CONTEXT } from '../contexts';
import { track } from '../../';

function trackLegislatorProfileEvent(event, data = null) {
  track(event, INTEL_FEDERAL_LEGISLATOR_PROFILE_CONTEXT, data);
}

export function trackNavItemVisited(section) {
  trackLegislatorProfileEvent('side nav - item visited', { section });
}

export function trackKeyDetailsVisited(type) {
  trackLegislatorProfileEvent('key details - details visited', { type });
}

export function trackLegislatorCommitteeVisited(name) {
  trackLegislatorProfileEvent('committees - committee visited', { name });
}

export function trackCaucusesShowLess() {
  trackLegislatorProfileEvent('caucuses - less caucuses shown');
}

export function trackCaucusesShowMore() {
  trackLegislatorProfileEvent('caucuses - more caucuses shown');
}

export function trackLegislatorsBillsFiltered(filter, input) {
  trackLegislatorProfileEvent('legislators bills - results filtered', { filter, input });
}

export function trackLegislatorsBillsSorted(column, direction) {
  trackLegislatorProfileEvent('legislators bills - results sorted', { column, direction });
}

export function trackLegislatorsBillVisited(billId) {
  trackLegislatorProfileEvent('legislators bills - bill visited', { bill: billId });
}

export function trackLegislatorsBillSponsorVisited(legislator) {
  trackLegislatorProfileEvent('legislators bills - legislator visited', {
    legislator,
    relationship: 'sponsor',
  });
}

export function trackLegislatorsBillCosponsorVisited(legislator) {
  trackLegislatorProfileEvent('legislators bills - legislator visited', {
    legislator,
    relationship: 'cosponsor',
  });
}

export function trackLegislatorsBillsPageVisited(page) {
  trackLegislatorProfileEvent('legislators bills - page visited', { page });
}

export function trackVoteComparisonRan(legislator) {
  trackLegislatorProfileEvent('vote comparison - comparison ran', { legislator });
}

export function trackLegislatorsBillsVoteComparisonRan(legislator) {
  trackLegislatorProfileEvent('legislators bills - comparison ran', { legislator });
}

export function trackVotingRecordFiltered(filter, input) {
  trackLegislatorProfileEvent('voting record - results filtered', { filter, input });
}

export function trackVotingRecordSorted(column, direction) {
  trackLegislatorProfileEvent('voting record - results sorted', { column, direction });
}

export function trackVotingRecordVoteVisited(vote) {
  trackLegislatorProfileEvent('voting record - vote visited', { vote });
}

export function trackVotingRecordIssueVisited(issue, vote) {
  trackLegislatorProfileEvent('voting record - issue visited', { issue, vote });
}

export function trackVotingRecordPageVisited(page) {
  trackLegislatorProfileEvent('voting record - page visited', { page });
}

export function trackLargestEmployersEmployerVisited(employer) {
  trackLegislatorProfileEvent('district info - employers - employer visited', { employer });
}

export function trackLargestEmployersResultsSorted(column, direction) {
  trackLegislatorProfileEvent('district info - employers - results sorted', { column, direction });
}

export function trackLargestEmployersEmployerCalled(employer) {
  trackLegislatorProfileEvent('district info - employers - employer called', { employer });
}

export function trackLargestEmployersPageVisited(page) {
  trackLegislatorProfileEvent('district info - employers - employer called', { page });
}

export function trackNewsResultsFiltered(input) {
  trackLegislatorProfileEvent('news - results filtered', { filter: 'keyword', input });
}

export function trackNewsResultVisited(url) {
  trackLegislatorProfileEvent('news - result visited', { url });
}

export function trackNewsResultPageVisited(page) {
  trackLegislatorProfileEvent('news - result visited', { page });
}
