import { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

function useElementById(id = '') {
  const [ref, setRef] = useState({});

  useEffect(() => {
    const $element = document.getElementById(id);

    if (isEmpty($element)) {
      return;
    }

    setRef($element);
  }, []);

  return {
    ref,
  };
}

export default useElementById;
