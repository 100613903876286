import isEmpty from 'lodash/isEmpty';

let ajax;

export function getFetcherOptions({ response, resTransform, optionsParser, requiredInputs }) {
  const options = resTransform
    ? resTransform({ requiredInputs, response })
    : optionsParser(response.data);

  if (isEmpty(options)) {
    return [];
  }

  return options;
}

function fetcher({
  uri,
  page,
  defaultRawValues,
  inputText,
  onSuccess,
  strategyInputs,
  strategySlug,
  reverseSearch,
  optionsParser = (options) => options || [],
  reqContentType,
  reqTransform,
  resTransform,
  reqMethod,
  customFetcher,
  multipleStrategySlugs,
  withAjaxAbort,
}) {
  const url = uri || '/api_web/suggester';
  const method = reqMethod || 'POST';
  let requiredInputs;

  if (reverseSearch) {
    requiredInputs = {
      values: defaultRawValues,
    };
  } else {
    requiredInputs = {
      query: inputText,
      page: page || 1,
      ...strategyInputs,
    };
  }

  const data = reqTransform
    ? reqTransform({ requiredInputs, strategySlug })
    : {
      strategy_slug: strategySlug,
      required_inputs: JSON.stringify(requiredInputs),
      reverse_search: reverseSearch,
    };

  if (customFetcher) {
    return customFetcher({
      multipleStrategySlugs,
      url,
      data,
      method,
      contentType: reqContentType,
      resTransform,
      optionsParser,
      requiredInputs,
      onSuccess,
    });
  }

  return new Promise((resolve) => {
    // do NOT abort previous ajax request when reverseSearching,
    // otherwise when opening a page with multiple suggester
    // with default values, loading their default values get ABORTED!
    // TODO: do we even need ajax.abort?
    if (withAjaxAbort && ajax && !reverseSearch) {
      ajax.abort();
    }

    ajax = $.ajax({
      url,
      data,
      method,
      contentType: reqContentType,
      success: (response) => {
        const options = getFetcherOptions({
          response,
          resTransform,
          requiredInputs,
          optionsParser,
        });

        onSuccess && onSuccess([...options], { options });
        resolve([...options]);
      },
    });
  });
}

export default fetcher;
