function parseItem({ id: value, name: label }) {
  return { value, label };
}

export default function arrayToSelectableItems(list) {
  if (!list || !list.length) return list;
  const expectedKeys = ['label', 'value'];

  const needToParse = Object.keys(list[0])
    .some(item => !expectedKeys.includes(item));

  if (!needToParse) return list;

  return list.map(parseItem);
}
