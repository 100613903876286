const PHONE_REGEXP = /^\+1 \(\d{3}\) \d{3}-\d{4}$/;
const TWITTER_USERNAME_REGEXP = /^@?(\w){1,15}$/;
const FACEBOOK_PROFILE_URL_REGEXP = /^(?:(?:http|https):\/\/)?(?:www\.|m\.)?facebook.com\/.+$/i;
const TITLE_REGEXP = /^[\w'\-,.][^!¡?÷?¿\\+=@#$%ˆ&*(){}|~<>;:[\]]*$/i;
const URL_REGEXP = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export {
  PHONE_REGEXP,
  TWITTER_USERNAME_REGEXP,
  FACEBOOK_PROFILE_URL_REGEXP,
  TITLE_REGEXP,
  URL_REGEXP,
  EMAIL_REGEXP,
};
