export function loadScript(url, callback, onError = null) {
  const script = document.createElement('script');
  script.type = 'text/javascript';

  if (script.readyState) { // IE support
    script.onreadystatechange = () => {
      if (script.readyState === 'loaded' ||
          script.readyState === 'complete') {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = callback;
    script.onerror = onError;
  }

  script.src = url;
  document.getElementsByTagName('head')[0].appendChild(script);
}

export function loadGoogleMaps(onSuccess, onError = null) {
  const googleMapsKey = gon.googleMapsApiKey;
  const gMapsLink = `https://maps.googleapis.com/maps/api/js?key=${googleMapsKey}&language=en&libraries=places`;
  loadScript(gMapsLink, onSuccess, onError);
}

export function loadD3(callback) {
  loadScript('https://cdnjs.cloudflare.com/ajax/libs/d3/3.5.5/d3.min.js', callback);
}
