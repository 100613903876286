import { findLastIndex, difference, uniqBy } from 'lodash';

export default function validateUniqueness(message, propertyName) {
  return this.test('unique', message, function findDuplicates(list) {
    const { path } = this;
    const pathIndex = findLastIndex(list, difference(list, uniqBy(list, propertyName))[0]);

    if (list.length !== uniqBy(list, propertyName).length) {
      throw this.createError({
        path: `${path}.${pathIndex}.${propertyName}`,
        message,
      });
    }

    return true;
  });
}
