import isEmpty from 'lodash/isEmpty';

export function transformSort({ sort, direction }) {
  if (isEmpty(sort) || isEmpty(direction)) {
    return { toReset: true };
  }

  return {
    sort,
    direction,
    toReset: false,
  };
}

export function transformPaginator(data = {}, pageSize) {
  const { pagination = {} } = data;

  return {
    current: pagination.current_page,
    total: pagination.total_pages,
    totalCount: pagination.total_count,
    pageSize,
  };
}

export function transformTableColumns(tableColumns) {
  if (isEmpty(tableColumns)) return [];

  return tableColumns.map(({
    label,
    allow_toggle,
    sorting_key,
    key_name,
    custom_field,
  }) => ({
    label,
    allowToggle: allow_toggle,
    sortingKey: sorting_key,
    keyName: key_name,
    customField: custom_field,
    dimensionType: custom_field ? 'Custom fields' : 'System fields',
  }));
}
