import { track } from 'utils/tracking';

import { INTEL_STATE_BILL_PROFILE_CONTEXT } from '../contexts';

function trackStateBillProfileEvent(event, data = null) {
  track(event, INTEL_STATE_BILL_PROFILE_CONTEXT, data);
}

export function trackStateBillProfileAction(trackingFunction) {
  return (...params) => trackingFunction(
    INTEL_STATE_BILL_PROFILE_CONTEXT,
    ...params
  );
}

/** Similiar bills */
export function trackSimiliarBillsStateFiltered(state) {
  trackStateBillProfileEvent('similiar bills - state filtered', { state });
}

export function trackSimiliarBillsYearFiltered(year) {
  trackStateBillProfileEvent('similiar bills - year filtered', { year });
}

/** Key Details */
export function trackBillText() {
  trackStateBillProfileEvent('key details - bill text');
}

export function trackOfficialSources() {
  trackStateBillProfileEvent('key details - official sources');
}

/** Your Activity */
export function trackAddNote() {
  trackStateBillProfileEvent('your activity - add note');
}

export function trackAddInteration() {
  trackStateBillProfileEvent('your activity - add interation');
}

export function trackYourActivityFilter(filterData) {
  trackStateBillProfileEvent('your activity - filtered', { filterData });
}

/** General Actions */
export function trackSaveSummary() {
  trackStateBillProfileEvent('save summary');
}

export function trackOpenExportModal() {
  trackStateBillProfileEvent('export modal - open');
}

export function trackGeneratedExport(exportOptions) {
  trackStateBillProfileEvent('export modal - export generated', { exportOptions });
}

/** Roll call votes */
export function trackRollCallVoteVisited(rollCallId) {
  trackStateBillProfileAction('roll call votes - roll call visited', { rollCall: rollCallId });
}

export function trackRollCallSorted(column, direction) {
  trackStateBillProfileAction('roll calls votes - results sorted', { column, direction });
}

export function trackRollCallPageVisited(page) {
  trackStateBillProfileAction('roll call votes - page visited', { page });
}

export function trackVotingRecordIssueVisited(issue, vote) {
  trackStateBillProfileAction('roll call votes - issue visited', { issue, vote });
}

/** Amendments */
export function trackAmendmentRecordVisited(amendmentId) {
  trackStateBillProfileAction('amendments - amendment visited', { amendment: amendmentId });
}

export function trackAmendmentSorted(column, direction) {
  trackStateBillProfileAction('amendments - results sorted', { column, direction });
}

export function trackAmendmentPageVisited(page) {
  trackStateBillProfileAction('amendments - page visited', { page });
}
