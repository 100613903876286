import PropTypes from 'prop-types';

import { paginatorShape } from 'components/core/Paginator/shapes';

export const uiShape = PropTypes.shape({
  isLoadingTags: PropTypes.bool.isRequired,
  isLoadingSubscriptions: PropTypes.bool,
  isSharingItems: PropTypes.bool.isRequired,
});

export const startEndDateShape = PropTypes.shape({
  start: PropTypes.string,
  end: PropTypes.string,
});

export const stringNumberType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);

export const labelValueShape = PropTypes.shape({
  label: PropTypes.string,
  value: stringNumberType,
});

const filterShape = PropTypes.shape({
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]),
  label: PropTypes.string,
});

export const commonSearchPropTypes = {
  id: PropTypes.number,
  filters: PropTypes.arrayOf(filterShape),
  title: PropTypes.string,
};

export const commonOptionsPropTypes = {
  billStatuses: PropTypes.arrayOf(labelValueShape),
  sponsors: PropTypes.arrayOf(labelValueShape),
  cosponsors: PropTypes.arrayOf(labelValueShape),
  tags: PropTypes.arrayOf(labelValueShape),
  position: PropTypes.arrayOf(labelValueShape),
  committees: PropTypes.arrayOf(labelValueShape),
  textExcludes: PropTypes.string,
  searchValue: PropTypes.string,
  showOnlyTrackedItems: PropTypes.bool,
};

export const introducedOverTimeShape = PropTypes.arrayOf(PropTypes.shape({
  count: PropTypes.number,
  date: PropTypes.string,
}));

export const commonUiPropTypes = {
  isInitialLoading: PropTypes.bool,
  isLoadingData: PropTypes.bool,
  isLoadingMyFeedDocuments: PropTypes.bool,
  isLoadingPositions: PropTypes.bool,
  isLoadingSubscriptions: PropTypes.bool,
  isLoadingTags: PropTypes.bool,
  isSavingSubscriptions: PropTypes.bool,
  isSharingItems: PropTypes.bool,
  toResetSelectedItems: PropTypes.bool,
};

export const billLookupUiShape = PropTypes.shape({
  ...commonUiPropTypes,
  isLoadingGraphData: PropTypes.bool,
});

export const sortShape = PropTypes.shape({
  toReset: PropTypes.bool,
});

export const commonLookupPropTypes = {
  defaultSubscription: PropTypes.string,
  paginator: paginatorShape,
};

export const exportReportShape = PropTypes.shape({
  ui: PropTypes.shape({
    isLoadingExports: PropTypes.bool,
  }),
});

export const commonAdvancedSearchPropTypes = {
  savedPaginator: paginatorShape,
  lastSearchId: PropTypes.number,
  ui: PropTypes.shape({
    isRecentSearchesLoading: PropTypes.bool,
    isRemovingItem: PropTypes.bool,
    isSavedSearchesLoading: PropTypes.bool,
    isSavingSearches: PropTypes.bool,
  }),
};

export const unknownObjectShape = PropTypes.shape({
  [PropTypes.string]: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]),
});

export const reactPdfStyleShape = PropTypes.shape({
  [PropTypes.string]: stringNumberType,
});
