import { track } from './index';
import { PUBLIC_FACING_CONTEXT } from './contexts';

function trackPublicFacingEvent(eventName, data = null) {
  track(eventName, PUBLIC_FACING_CONTEXT, data);
}

/** Bill Scorecards page */
function trackBillScorecardEvent(eventName) {
  trackPublicFacingEvent(`bill scorecards - ${eventName}`);
}

function trackBillVoteScorecardEvent(eventName) {
  trackPublicFacingEvent(`bill vote scorecards - ${eventName}`);
}

export const trackBillAdded = () => trackBillScorecardEvent('bill added');
export const trackBillRemoved = () => trackBillScorecardEvent('bill removed');
export const trackIframeCodeCopied = () => trackBillScorecardEvent('iframe code copied');
export const trackBillScorecardPreviewed = () => trackBillScorecardEvent('scorecard previewed');
export const trackBillVoteRemoved = () => trackBillVoteScorecardEvent('bill removed');
export const trackBillVoteAdded = () => trackBillVoteScorecardEvent('bill added');
export const trackBillVoteIframeCodeCopied = () => trackBillVoteScorecardEvent('iframe code copied');
export const trackBillVoteScorecardPreviewed = () => trackBillVoteScorecardEvent('scorecard previewed');

/** Grassroots action center */
function trackGrassrootsEvent(eventName, data = null) {
  trackPublicFacingEvent(`grassroots - ${eventName}`, data);
}

export const trackGrassrootsCampaignManagerVisited = () => trackGrassrootsEvent('campaign manager visited');
export const trackGrassrootsCampaignDisplayToggled = checked => trackGrassrootsEvent('campaign display toggled', { state: checked ? 'on' : 'off' });
export const trackGrassrootsCampaignEmbedableToggled = checked => trackGrassrootsEvent('iframe enable toggled', { state: checked ? 'enabled' : 'disabled' });
export const trackGrassrootsCampaignIframeCodeCopied = () => trackGrassrootsEvent('iframe code copied');

/** State Legislation Watchlists */
function trackStateLegWatchlistEvent(eventName, data = null) {
  trackPublicFacingEvent(`state leg watchlists - ${eventName}`, data);
}

export const trackWatchlistCreated = () => trackStateLegWatchlistEvent('watchlist created');
export const trackWatchlistCreatorVisited = () => trackStateLegWatchlistEvent('creator visited');
export const trackWatchlistResultEditorVisited = () => trackStateLegWatchlistEvent('result editor visited');
export const trackWatchlistResultVisited = () => trackStateLegWatchlistEvent('result visited');
export const trackWatchlistUpdated = () => trackStateLegWatchlistEvent('watchlist updated');
export const trackWatchlistDeleted = () => trackStateLegWatchlistEvent('watchlist deleted');
export const trackWatchlistIssueAreaCreated = () => trackStateLegWatchlistEvent('issue area created');
export const trackWatchlistIssueAreaEdited = () => trackStateLegWatchlistEvent('issue area edited');
export const trackWatchlistEditorVisited = () => trackStateLegWatchlistEvent('watchlist editor visited');
export const trackWatchlistIssueAreaCreatorVisited = () => trackStateLegWatchlistEvent('issue area creator visited');
export const trackWatchlistPreviewed = () => trackStateLegWatchlistEvent('previewed');
export const trackWatchlistIframeCodeCopied = () => trackStateLegWatchlistEvent('iframe code copied');
