import { useState } from 'react';

function useLoader(defaultState = false) {
  const [isLoading, setIsLoading] = useState(defaultState);

  function handleStartLoading() {
    setIsLoading(true);
  }

  function handleStopLoading() {
    setIsLoading(false);
  }

  return {
    isLoading,
    startLoading: handleStartLoading,
    stopLoading: handleStopLoading,
    setIsLoading,
  };
}

export default useLoader;
