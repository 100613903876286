/* eslint-disable no-restricted-globals */
import 'url-search-params-polyfill';
import forEach from 'lodash/forEach';

/**
 * Updates a query string without reloading the page
 * Includes browser history update
 * @param {string} queryString new query string
 */
export function setUrlQueryString(queryString) {
  if (history.pushState) {
    const { protocol, pathname, host } = window.location;
    const newUrl = `${protocol}//${host}${pathname}?${queryString}`;

    window.history.pushState({ path: newUrl }, '', newUrl);
  }
}

/**
 * Updates a query string param value without reloading the page
 * Includes browser history update
 * @param {string} key param name
 * @param {string} value new value
 */
export function setUrlParam(key, value) {
  if (history.pushState) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    const { protocol, pathname, host } = window.location;
    const newurl = `${protocol}//${host}${pathname}?${searchParams.toString()}`;
    window.history.pushState({ path: newurl }, '', newurl);
  }
}

export function batchSetUrlParams({ params, keepCurrentParams = true }) {
  if (history.pushState) {
    const baseUrlParams = keepCurrentParams ? window.location.search : '';
    const searchParams = new URLSearchParams(baseUrlParams);

    forEach(params, (value, key) => {
      searchParams.set(key, value);
    });

    const { protocol, pathname, host } = window.location;
    const newurl = `${protocol}//${host}${pathname}?${searchParams.toString()}`;
    window.history.pushState({ path: newurl }, '', newurl);
  }
}

/**
 * Get url param value (query string)
 * @param {string} key param name
 */
export function getUrlParam(key) {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(key);
}

/**
 * Get url all params value (query string)
 * @param {string} key param name
 * @returns {Array} values
 */
export function getAllUrlParamValues(key) {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.getAll(key);
}
