export const PDF_ID = 'pdf';
export const DOCX_ID = 'docx';
export const CSV_ID = 'csv';
export const DOC_ID = 'doc';
export const ODF_ID = 'odf';
export const XLS_ID = 'xls';
export const XLSX_ID = 'xlsx';
export const RTF_ID = 'rtf';
export const TXT_ID = 'txt';
export const JPEG_ID = 'jpeg';
export const JPG_ID = 'jpg';
export const PNG_ID = 'png';
export const GIF_ID = 'gif';
export const SVG_ID = 'svg';
export const MP3_ID = 'mp3';
export const WAV_ID = 'wav';
export const WMA_ID = 'wma';

export const IMAGE_FILE_EXTENSIONS = [JPEG_ID, JPG_ID, PNG_ID, GIF_ID, SVG_ID];

export const AUDIO_FILE_EXTENSIONS = [MP3_ID, WAV_ID, WMA_ID];

export const DOCUMENT_FILE_EXTENSIONS = [
  DOC_ID,
  DOCX_ID,
  ODF_ID,
  XLS_ID,
  XLSX_ID,
  PDF_ID,
  RTF_ID,
  TXT_ID,
];

export const PDF_LABEL = 'PDF';
export const DOCX_LABEL = 'Word (.docx)';
export const CSV_LABEL = 'CSV';
export const XLSX_LABEL = 'Excel (.xlsx)';
export const EXPORT_RECORD_LIMIT = 1000;
export const EXPORT_DOCX_RECORD_LIMIT = 500;
export const EXPORT_GRASSROOTS_RECORD_LIMIT = 10000;
export const EXPORT_EMAIL_LIMIT = 100;

export const LIMIT_PDF_SELECTED_ITEMS = 100;
export const LIMIT_DOCX_SELECTED_ITEMS = 500;
export const LIMIT_CSV_SELECTED_ITEMS = 1000;
export const LIMIT_SELECTED_ITEMS = 500;

export const LOGO_CUSTOMIZATION_ID = 'logoCustomization';

export const getCorsImageUrl = url => (url ? `${url}?v1` : '');

export const SUMMARY_CUSTOMIZATION_ID = 'summaryCustomization';
export const SHOW_PAGE_CUSTOMIZATION_ID = 'showPageCustomization';
export const CHOOSE_FIELDS_CUSTOMIZATION_ID = 'chooseFieldsCustomization';

export const FILE_FORMAT_DESCRIPTION = 'Choose the file format. Up to 100 bills will appear in PDF, up to 500 in CSV or Word (.docx).';

export const EXPORT_FILE_ITEMS = [
  { id: PDF_ID, title: PDF_LABEL },
  { id: DOCX_ID, title: DOCX_LABEL },
  { id: CSV_ID, title: CSV_LABEL },
];

export const FILE_ITEMS = [
  { id: PDF_ID, title: PDF_LABEL },
  { id: DOCX_ID, title: DOCX_LABEL },
  { id: CSV_ID, title: CSV_LABEL },
  { id: XLSX_ID, title: XLSX_LABEL },
];

export const LOGO_CUSTOMIZATION_ITEM = {
  id: LOGO_CUSTOMIZATION_ID,
  title: 'Show my logo',
};

export const SUMMARY_CUSTOMIZATION_ITEM = {
  id: SUMMARY_CUSTOMIZATION_ID,
  title: 'Add report summary',
};

export const SHOW_PAGE_CUSTOMIZATION_ITEM = {
  id: SHOW_PAGE_CUSTOMIZATION_ID,
  title: 'Show page numbers',
};

export const CHOOSE_FIELDS_CUSTOMIZATION_ITEM = {
  id: CHOOSE_FIELDS_CUSTOMIZATION_ID,
  title: 'Choose what columns appear',
};
