import { LIMIT_SELECTED_ITEMS } from 'redux/exportReport/constants';
import GlobalNotifier from 'components/GlobalNotifier';

import {
  SUCCESS_NOTIFICATION,
  FAILURE_NOTIFICATION,
  INFO_NOTIFICATION,
} from './constants';

const { addNotification } = GlobalNotifier;

function sendNotification(notification) {
  notification && addNotification(notification);
}

export function sendSuccessNotification(message) {
  sendNotification({
    message,
    level: SUCCESS_NOTIFICATION,
  });
}

export function sendFailureNotification(message) {
  sendNotification({
    message,
    level: FAILURE_NOTIFICATION,
  });
}

export function sendInfoNotification(message) {
  sendNotification({
    message,
    level: INFO_NOTIFICATION,
  });
}

export function getLimitExceedError(limitSelectedItems = LIMIT_SELECTED_ITEMS) {
  return sendFailureNotification(`You cannot export more than ${limitSelectedItems} items. Please try again.`);
}

export function getLimitExceedInfo(limitSelectedItems = LIMIT_SELECTED_ITEMS) {
  return sendInfoNotification(`Your export will be limited to ${limitSelectedItems} items.`);
}
