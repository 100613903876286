import { track } from './';
import { ADVOCACY_HILLDAY_CONTEXT, ADVOCACY_HILLDAY_BULK_INVITE_CONTEXT, ADVOCACY_HILLDAY_IMPORTER_CONTEXT } from './contexts';

function getContext(isBulkInvite) {
  if (isBulkInvite) {
    return ADVOCACY_HILLDAY_BULK_INVITE_CONTEXT;
  }

  return ADVOCACY_HILLDAY_CONTEXT;
}

function createHillDayTracker(eventName, isBulkInvite = false) {
  return () => track(eventName, getContext(isBulkInvite));
}

function createHillDayTrackerWithMenu(eventName, isBulkInvite = false) {
  return ({ menu }) => track(eventName, getContext(isBulkInvite), { menu });
}

export const trackInviteAttendeeVisited = createHillDayTracker('invite attendee visited');

export const trackBulkInviteAttendeesVisited = createHillDayTracker('bulk invite attendees visited');

export const trackDocumentEditVisited = createHillDayTrackerWithMenu('document edit visited');

export const trackDocumentUpdated = createHillDayTracker('document updated');

export const trackDocumentCreated = createHillDayTracker('document created');

export const trackaAttendeeInviteSend = createHillDayTracker('attendee invite sent');

export const trackAttendeeInviteResent = createHillDayTracker('invite resent');

export const trackAttendeeRemoved = createHillDayTracker('attendee removed');

export const trackDocumentDeleted = createHillDayTrackerWithMenu('document deleted');

export const trackDocumentViewed = createHillDayTrackerWithMenu('document viewed');

export const trackHillDayCreated = createHillDayTracker('hill day created');

export const trackHillDayEditVisited = createHillDayTracker('hill day edit page visited');

export const trackHillDayDeleted = createHillDayTracker('hill day deleted');

export const trackHillDayViewed = createHillDayTrackerWithMenu('hill day viewed');

export const trackHillCreatorVisited = createHillDayTracker('hill day creator visited');

export const trackFiltersToggled = state => track('filters toggled', ADVOCACY_HILLDAY_CONTEXT, { state: state ? 'show' : 'hide' });

export const trackLegislatorVisitedFromMeetingList = () =>
  track('legislator visited', ADVOCACY_HILLDAY_CONTEXT, { menu: 'meeting list' });

export const trackMeetingEdited = createHillDayTracker('meeting updated');

export const trackMeetingCreated = createHillDayTracker('meeting created');

export const trackMeetingCreatorVisited = createHillDayTracker('meeting creator visited');

export const trackPrepareReturnBack = createHillDayTracker('prepare return back', true);

export const trackNoDataBackBulkAttendees = createHillDayTracker('no data return back', true);

export const trackImportPreviewed = createHillDayTracker('import previewed', true);

export const trackImported = createHillDayTracker('imported', true);

export const trackAttendeeVisited = createHillDayTrackerWithMenu('attendee visited');

export const trackMeetingVisited = () => track('meeting visited', ADVOCACY_HILLDAY_CONTEXT, { menu: 'meeting list' });

export const trackPreviewReturnBack = createHillDayTracker('preview return back', true);

export const trackImporterImportBegan = (hillDayName, hillDayId, importType) =>
  track('import began', ADVOCACY_HILLDAY_IMPORTER_CONTEXT, { hillDayName, hillDayId, importType });
