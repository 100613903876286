// People
export const STAKEHOLDER_SLUG = 'stakeholder_mgmt_stakeholders';
export const CONGRESSIONAL_STAFFERS_SLUG = 'rolodex';
export const FEDERAL_AGENCY_STAFFERS_SLUG = 'federal_agency_contacts';
export const FEDERAL_EXECUTIVES_SLUG = 'federal_executives';
export const STATE_EXECUTIVES_SLUG = 'state_executives';
export const LOCAL_EXECUTIVES_SLUG = 'local_executives';
export const STATE_AGENCY_STAFFERS_SLUG = 'state_staffer_executives';
export const STATE_STAFFERS_SLUG = 'state_legislation_staffers';
export const FEDERAL_LEGISLATORS_SLUG = 'federal_legislators';
export const STATE_LEGISLATORS_SLUG = 'state_legislation_legislators';
export const FEDERAL_EXECUTIVE_SLUG = 'federal_executive';
export const STATE_EXECUTIVE_SLUG = 'state_executive';
export const STATE_STAFFER_EXECUTIVE_SLUG = 'state_staffer_executive';
export const LOCAL_EXECUTIVE_SLUG = 'local_executive';
export const LOCAL_MUNICIPAL_EXECUTIVE_SLUG = 'municipal_executive';
export const LOCAL_COUNTY_EXECUTIVE_SLUG = 'county_executive';

// Legs & Regs
export const FEDERAL_BILLS_SLUG = 'federal_bills';
export const STATE_BILLS_SLUG = 'state_legislation_bills';
export const FEDERAL_REGULATIONS_SLUG = 'federal_register_regulations';
export const STATE_REGULATIONS_SLUG = 'state_legislation_regulations';

// Committees
export const FEDERAL_COMMITTEE_SLUG = 'federal_committees';
export const STATE_COMMITTEE_SLUG = 'state_legislation_committees';

// Documents
export const AGENCY_PRESS_RELEASES_SLUG = 'doctrove_agency_press_releases';
export const CBO_COST_ESTIMATES_SLUG = 'cbo_gov';
export const COMMITTEE_REPORTS_SLUG = 'committee_reports';
export const CONGRESSIONAL_PRESS_RELEASES_SLUG = 'press_releases';
export const CONGRESSIONAL_RECORD_ITEMS_SLUG = 'congressional_record';
export const CONTRACTS_SLUG = 'fbo_gov';
export const CRS_REPORTS_SLUG = 'doctrove_crs';
export const DEAR_COLLEAGUE_LETTERS_SLUG = 'doctrove_dear_colleague_letters';
export const EXECUTIVE_ORDERS_SLUG = 'doctrove_executive_orders';
export const GAO_REPORTS_SLUG = 'gao_gov';
export const GRANTS_SLUG = 'grants_gov';
export const LD2_REPORTS = 'lobby_filing_issues';
export const LIVE_HEARING_TRANSCRIPTS_SLUG = 'doctrove_live_hearing_transcripts';
export const OFFICIAL_HEARING_TRANSCRIPTS_SLUG = 'doctrove_hearing_transcripts';
export const SAP_REPORTS_SLUG = 'doctrove_sap';
export const STATE_OF_UNION_ADDRESSES_SLUG = 'doctrove_union_statements';
export const TREATY_DOCUMENTS_SLUG = 'treaties';
export const THIRD_PARTY_REPORTS_SLUG = 'doctrove_third_party_reports';
export const WHITE_HOUSE_PRESS_BRIEFINGS_SLUG = 'doctrove_press_briefings';
export const GOVERNOR_ORDERS_SLUG = 'doctrove_governor_orders';
export const GOVERNOR_PRESS_RELEASES_SLUG = 'doctrove_governor_press_releases';

// Social media
export const TWITTER_SLUG = 'social_media';

// Federal Documents United Array
export const FEDERAL_DOCUMENTS = [
  AGENCY_PRESS_RELEASES_SLUG,
  CBO_COST_ESTIMATES_SLUG,
  COMMITTEE_REPORTS_SLUG,
  CONGRESSIONAL_PRESS_RELEASES_SLUG,
  CONGRESSIONAL_RECORD_ITEMS_SLUG,
  CONTRACTS_SLUG,
  CRS_REPORTS_SLUG,
  DEAR_COLLEAGUE_LETTERS_SLUG,
  EXECUTIVE_ORDERS_SLUG,
  GAO_REPORTS_SLUG,
  GRANTS_SLUG,
  LIVE_HEARING_TRANSCRIPTS_SLUG,
  OFFICIAL_HEARING_TRANSCRIPTS_SLUG,
  SAP_REPORTS_SLUG,
  STATE_OF_UNION_ADDRESSES_SLUG,
  TREATY_DOCUMENTS_SLUG,
  THIRD_PARTY_REPORTS_SLUG,
  WHITE_HOUSE_PRESS_BRIEFINGS_SLUG,
];
