import { track } from '../';
import { INTEL_CONTEXT } from './contexts';

export * from './federal_doc_trove';
export * from './state_legislation';
export * from './federal_legislation';
export * from './rolodex';
export * from './federal/legislatorProfile';
export * from './state/committeeProfile';
export * from './state/stafferProfile';
export * from './federal/committeeProfile';

export function trackIntelEvent(eventName, data = null) {
  track(eventName, INTEL_CONTEXT, data);
}

export function trackCosponsorsOracleSearched() {
  trackIntelEvent('cosponsors oracle - searched');
}

export function trackLegislatorSimilarBillsViewed() {
  trackIntelEvent('cosponsors oracle - legislator similar bills viewed');
}

export function trackLegislatorViewed() {
  trackIntelEvent('cosponsors oracle - legislator viewed');
}

export function trackFederalBillsSearched() {
  trackIntelEvent('document similarity - federal bills searched');
}

export function trackCosponsorOracleSearched() {
  trackIntelEvent('document similarity - cosponsor oracle searched');
}

export function trackFederalCosponsorshipSimilaritySearched() {
  trackIntelEvent('federal cosponsorship similarity - searched');
}

export function trackStateCosponsorshipSimilaritySearched() {
  trackIntelEvent('state - cosponsor oracle - cosponsor similarity - searched');
}

export function trackCompareVotingAffinities() {
  trackIntelEvent('voting affinities - compared');
}

export function trackSocialMediaSearch(keywords) {
  trackIntelEvent('social media - searched', { keywords });
}

export function trackSocialMediaFiltersToggled({ wasShowed }) {
  const state = wasShowed ? 'off' : 'on';
  trackIntelEvent('social media - filters toggled', { state });
}

export function trackSocialMediaProfileVisited() {
  trackIntelEvent('social media - profile visited');
}

export function trackSocialMediaSourceVisited() {
  trackIntelEvent('social media - source visited');
}

export function trackSocialMediaResultVisited() {
  trackIntelEvent('social media - result visited');
}

export function trackSocialMediaLegislatorVisited() {
  trackIntelEvent('social media - legislator visited');
}

export function trackSocialMediaRetweeted() {
  trackIntelEvent('social media - retweeted');
}

export function trackBattingAveragesSearched() {
  trackIntelEvent('state - cosponsor oracle - batting averages - searched');
}

export function trackBattingAveragesLegislatorVisited() {
  trackIntelEvent('state - cosponsor oracle - batting averages - legislator visited');
}

export function trackBillsSimilaritySearch() {
  trackIntelEvent('state - cosponsor oracle - bills similarity - searched');
}

export function trackGovernorOrdersSearched() {
  trackIntelEvent('state doc trove - governor orders - searched');
}

export function trackGovernorOrdersResultVisited() {
  trackIntelEvent('state doc trove - governor orders - result visited');
}

export function trackStateGovernorPressReleasesSearched() {
  trackIntelEvent('state doc trove - governor press releases - searched');
}

export function trackStateGovernorPressReleasesResultVisited() {
  trackIntelEvent('state doc trove - governor press releases - result visited');
}

export function trackStateCosponsorSimilarityResultsFiltered() {
  trackIntelEvent('state - cosponsor oracle - cosponsor similarity - results filtered');
}
