import { isString, isEmpty } from 'lodash';

function extractUrls(text) {
  const regexpUrl = /((http|ftp|https):\/\/)?[-a-zA-Z0-9:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9:%_+.~#?&//=]*)/g;
  const regexpMail = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b/i;

  if (isEmpty(text)) return undefined;

  if (!isString(text)) {
    window.Sentry.captureMessage(`The textWithUrls argument should be a string, got ${typeof text}`);
  }

  const textWithoutEmails = text.replace(regexpMail, '');

  return textWithoutEmails.match(regexpUrl);
}

export default extractUrls;
