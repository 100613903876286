import React from 'react';
import PropTypes from 'prop-types';
import { isObject } from 'lodash';

import CoreLink from 'components/core/Link';
import { isExternal as isLinkExternal, isOnPathname } from 'utils/url';

function Link({ children, href, path, ...restProps }) {
  const url = isObject(href) ? href.pathname : href;
  const isExternalUrl = isLinkExternal(url);
  const isOnPath = isOnPathname(path);
  const internalProps = href && isOnPath && !isExternalUrl && {
    isRouterLink: true,
    to: href,
  };

  return (
    <CoreLink
      {...restProps}
      {...internalProps}
      href={url}
      isExternal={isExternalUrl}
    >
      {children}
    </CoreLink>
  );
}

Link.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.node,
  ]),
  href: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }),
  ]),
  path: PropTypes.string,
};

export default Link;
