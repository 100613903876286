export function getBillStatusTypes(chamber, currentStep) {
  const introduced = { id: 'Introduced', label: 'Introduced' };
  const referredToCommittee = {
    id: 'Referred to committee',
    label: 'Referred to committee',
    pdfStyle: { left: -20 },
  };
  const reportedOutOfCommittee = {
    id: 'Reported out of committee',
    label: 'Reported out of committee',
    pdfStyle: { left: -20 },
  };
  const passedSenate = {
    id: 'Passed Senate',
    label: 'Passed Senate',
    isFailed: currentStep === 'Failed:Passed Senate',
    pdfStyle: { left: -18 },
  };
  const passedHouse = {
    id: 'Passed House',
    label: 'Passed House',
    isFailed: currentStep === 'Failed:Passed House',
    pdfStyle: { left: -14 },
  };
  const law = {
    id: 'Law',
    label: 'Law',
    isFailed: currentStep === 'Failed:Law',
    pdfStyle: { left: -2 },
  };

  if (currentStep === 'Passed') {
    return [];
  }

  if (chamber === 'house') {
    return [
      introduced,
      referredToCommittee,
      reportedOutOfCommittee,
      passedHouse,
      passedSenate,
      law,
    ];
  }

  return [
    introduced,
    referredToCommittee,
    reportedOutOfCommittee,
    passedSenate,
    passedHouse,
    law,
  ];
}
