import { find, isEmpty, isNil, omitBy, toString } from 'lodash';

export function getLookupRequestParams(payload, transformOptionsForBE) {
  const {
    dataProps = {},
    searchData = {},
    appliedFilters,
    clearAll,
    sort,
    direction,
    page,
    url,
  } = payload;

  const isSearch = !(isEmpty(searchData) && !clearAll);
  const searchQuery = transformOptionsForBE(isSearch ? searchData : appliedFilters, dataProps);
  const searchForm = !isEmpty(searchQuery) && { search_form: searchQuery };

  const sortData = omitBy({ sort, direction, page }, isNil);
  const data = { ...searchForm, ...sortData };

  return { isSearch, url, data, sortData };
}

export function transformTags({ payload, id, type }) {
  const { tags: transformedTags } = find(payload, ({ recordId, recordType }) => {
    if (isEmpty(recordType) || isEmpty(type)) {
      return toString(recordId) === toString(id);
    }

    return (toString(recordId) === toString(id))
      && (recordType === type);
  }) || {};

  return transformedTags || [];
}

export function getColumnElement({
  elements,
  recordType,
  recordId,
  tags,
  sharings,
  trackings,
} = {}) {
  return find(elements, (element) => {
    const { id, type } = element.dataset;

    if (tags) {
      element.dataset.tags = JSON.stringify(tags);
    }

    if (sharings) {
      element.dataset.sharings = JSON.stringify(sharings);
    }

    if (trackings) {
      element.dataset.trackings = JSON.stringify(trackings);
    }

    if (isEmpty(recordType) || isEmpty(type)) {
      return toString(recordId) === toString(id);
    }

    return (toString(recordId) === toString(id))
      && (recordType === type);
  });
}
