import { FACEBOOK_LINK, TWITTER_LINK, LINKEDIN_LINK } from 'utils/constants/socialMedia';

export function getMailLinkFromObj(mailInfo = {}) {
  const { subject = '', email = '', body = '' } = mailInfo;

  if (subject || body) {
    return `mailto:${email}?body=${body}&subject=${subject}`;
  }

  return `mailto:${email}`;
}

export function getMailLink(body) {
  return getMailLinkFromObj({ body });
}
export function getDirectMailLink(email) {
  return getMailLinkFromObj({ email });
}

export function getPhoneLink(phone) {
  return `tel:${phone}`;
}

export function getFacebookLink(link) {
  return `${FACEBOOK_LINK}/dialog/share?app_id=${gon.facebookAppId}&href=${link}`;
}

export function getFacebookDescriptionLink(link, quote) {
  return `${FACEBOOK_LINK}/dialog/share?app_id=${gon.facebookAppId}&href=${link}&quote=${quote}&display=page`;
}

export function getTwitterShareLink({ link, text, viaTweetAccount = 'GovPredict' }) {
  return `${TWITTER_LINK}/share?url=${link}&text=${text}&via=${viaTweetAccount}`;
}

export function getTwitterLink(text) {
  return `${TWITTER_LINK}/intent/tweet?text=${text}`;
}

export function getTwitterProfileLink(profileName) {
  return `${TWITTER_LINK}/${profileName}`;
}

export function getFacebookProfileLink(profileName) {
  return `${FACEBOOK_LINK}/${profileName}`;
}

export function getLinkedInProfileLink(profileName) {
  return `${LINKEDIN_LINK}/in/${profileName}`;
}

export function getLinkedInShareLink(link) {
  return `${LINKEDIN_LINK}/shareArticle?url=${link}`;
}
