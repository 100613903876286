import * as Yup from 'yup';
import { reduce, filter, isEmpty } from 'lodash';

export default function getCustomFieldsSchema(customFields, errorMessage) {
  const requiredFields = filter(customFields, 'required');

  const getSchema = (customField) => {
    if (isEmpty(customField.options)) {
      return Yup.string().required(errorMessage);
    }

    return Yup.array().nullable().required(errorMessage);
  };

  return reduce(requiredFields, (acc, customField) => ({
    ...acc,
    [customField.token]: getSchema(customField),
  }), {});
}
