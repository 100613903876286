import { INTEL_FEDERAL_COMMITTEE_PROFILE_CONTEXT } from '../contexts';
import { track } from '../../';

function trackFederalCommitteeProfileEvent(event, data = null) {
  track(event, INTEL_FEDERAL_COMMITTEE_PROFILE_CONTEXT, data);
}

export function trackFederalCommitteeNavItemVisited(section) {
  trackFederalCommitteeProfileEvent('side nav - item visited', { section });
}

export function trackFederalCommitteeBillsFiltered(filter, input) {
  trackFederalCommitteeProfileEvent('bills - results filtered', { filter, input });
}

export function trackFederalCommitteeBillsSorted(column, direction) {
  trackFederalCommitteeProfileEvent('bills - results sorted', { column, direction });
}

export function trackFederalCommitteeBillVisited(billId) {
  trackFederalCommitteeProfileEvent('bills - bill visited', { bill: billId });
}

export function trackFederalCommitteeBillSponsorVisited(legislator) {
  trackFederalCommitteeProfileEvent('bills - legislator visited', {
    legislator,
    relationship: 'sponsor',
  });
}

export function trackFederalCommitteeBillCosponsorVisited(legislator) {
  trackFederalCommitteeProfileEvent('bills - legislator visited', {
    legislator,
    relationship: 'cosponsor',
  });
}

export function trackFederalCommitteeBillsPageVisited(page) {
  trackFederalCommitteeProfileEvent('bills - page visited', { page });
}
