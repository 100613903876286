import { includes, isEmpty } from 'lodash';

const addProtocolToUrl = (url) => {
  if (isEmpty(url)) {
    return url;
  }

  return (includes(url, 'https://') || includes(url, 'http://')) ? url
    : `https://${url}`;
};

export default addProtocolToUrl;
