import { isEmpty, isBoolean, isNumber, uniqBy, flattenDeep } from 'lodash';
import moment from 'moment';

import { GLOBAL_DATE_FORMAT } from 'utils/constants/date';

export function getDateLabel(startDate, endDate) {
  if (!startDate && !endDate) {
    return '';
  }

  const formattedStartDate = startDate && moment(startDate).format(GLOBAL_DATE_FORMAT);
  const formattedEndDate = endDate && moment(endDate).format(GLOBAL_DATE_FORMAT);

  if (!startDate && endDate) {
    return `to ${formattedEndDate}`;
  }

  if (!endDate && startDate) {
    return `from ${formattedStartDate}`;
  }

  return `${formattedStartDate}-${formattedEndDate}`;
}

export function getRangeLabel(start, end) {
  if (start === 0 && end === 0) {
    return 'none';
  }

  if (!start && !end) {
    return '';
  }

  if (!start && end) {
    return `to ${end}`;
  }

  if (!end && start) {
    return `from ${start}`;
  }

  return `${start}-${end}`;
}

export function getValue(key, value) {
  if (isBoolean(value) && value) {
    return { [key]: value };
  }

  if (isNumber(value)) {
    return { [key]: value };
  }

  if (isEmpty(value)) {
    return null;
  }

  return { [key]: value };
}

export function getLabelFromItem(items) {
  if (isEmpty(items)) {
    return [];
  }

  return items.map(item => item.label);
}

export function getUniqueLabelFromItem(items) {
  if (isEmpty(items)) {
    return [];
  }

  return uniqBy(items, 'label').map(item => item.label);
}

export function transformSelectValues(items) {
  if (isEmpty(items)) {
    return [];
  }

  return items.map(item => item.value);
}

export function transformArraySelectValues(items) {
  if (isEmpty(items)) {
    return [];
  }

  return flattenDeep(items.map(item => item.value));
}

export function formatSelectValue(items) {
  if (isEmpty(items)) {
    return [];
  }

  return items.map(item => ({ label: item, value: item }));
}

export function mapBackendValues(state, values) {
  if (isEmpty(state) || isEmpty(values)) {
    return null;
  }

  return state.map(value => values[value]);
}

export function mapValuesUI(state, values) {
  if (isEmpty(state) || isEmpty(values)) {
    return null;
  }

  return formatSelectValue(mapBackendValues(state, values));
}

export function getMapSelectValue(valuesMap = {}, key) {
  if (isEmpty(valuesMap) || isEmpty(key)) {
    return {};
  }

  return { value: key, label: valuesMap[key] };
}

export function getMapDisplayItem(valuesMap = {}, key) {
  const selectValue = getMapSelectValue(valuesMap, key);

  if (isEmpty(selectValue)) {
    return [];
  }

  return getLabelFromItem([selectValue]);
}

export function getItemValue(item) {
  if (isEmpty(item)) {
    return null;
  }

  return item.value;
}

export function transformMapSelectValues(valueLabelMap = {}, reverseArray = false) {
  const selectOptions = Object.keys(valueLabelMap).reduce(
    (acumulator, currentKey) =>
      [{ value: currentKey, label: valueLabelMap[currentKey] }, ...acumulator]
    , []);

  if (reverseArray) {
    return selectOptions.reverse();
  }

  return selectOptions;
}
