import PropTypes from 'prop-types';

import { stringNumberType } from '.';

export const votingRecordShape = PropTypes.shape({
  id: PropTypes.string,
  date: PropTypes.string,
  billId: stringNumberType,
  billName: PropTypes.string,
  billTitle: PropTypes.string,
  billUrl: PropTypes.string,
  voteType: PropTypes.string,
  voteDate: PropTypes.string,
  voteNumber: PropTypes.string,
  result: PropTypes.string,
  voteQuestion: PropTypes.string,
  voteNumberUrl: PropTypes.string,
});
