import { useState } from 'react';
import { isFunction, find, get } from 'lodash';

import { SEARCH_QUERY_PARAM } from 'components/core/PageLookup/utils/constants';

function useFilterValues({
  handleFetchData,
  emptyState,
  emptyOptions,
  isMobile,
  collapseAccordion,
  onAccordionToggle,
  isAccordionOpen = {},
  filters,
  clearParams,
  searchQuery = '',
}) {
  const [filterValues, setFilterValues] = useState({ ...emptyState, searchValue: searchQuery });

  function getFilterId(field) {
    return get(find(filters, ({ id, keyName }) => field === id || field === keyName), 'id');
  }

  function onFetchData({ updatedFilterValues = filterValues, ...restProps } = {}) {
    handleFetchData({ ...restProps, updatedFilterValues });
  }

  function getUpdatedFilterValues({ updatedOptions, updatedSearch }) {
    return {
      ...filterValues,
      options: {
        ...filterValues.options,
        ...updatedOptions,
      },
      ...(updatedSearch && { searchValue: updatedSearch }),
    };
  }

  function handleFetchAndUpdateFilters({
    updatedFilterValues,
    shouldFetch = true,
    isPreviousSearch = false,
  }) {
    shouldFetch && onFetchData({ updatedFilterValues, isPreviousSearch });
    setFilterValues(updatedFilterValues);
  }

  function setSearchValue(value) {
    const updatedFilterValues = { ...filterValues, searchValue: value };

    handleFetchAndUpdateFilters({ updatedFilterValues });
  }

  function setOptions(updatedOptions, isPreviousSearch = false) {
    const updatedFilterValues = getUpdatedFilterValues({ updatedOptions });

    handleFetchAndUpdateFilters({ updatedFilterValues, isPreviousSearch });
  }

  function setSearchAndOptions(updatedOptions, updatedSearch, isPreviousSearch = false) {
    const updatedFilterValues = getUpdatedFilterValues({ updatedOptions, updatedSearch });

    handleFetchAndUpdateFilters({ updatedFilterValues, isPreviousSearch });
  }

  function handleAccordionFieldChange(field) {
    !isAccordionOpen[field] && onAccordionToggle && onAccordionToggle([field]);
  }

  function handleFilterChange(field, value, options = {}) {
    const { shouldFetch = !isMobile, optionalFields } = options;
    const updatedOptions = { [field]: value, ...optionalFields };
    const updatedFilterValues = getUpdatedFilterValues({ updatedOptions });
    handleAccordionFieldChange(getFilterId(field));

    handleFetchAndUpdateFilters({ updatedFilterValues, shouldFetch });
  }

  function handleTagClick(tag) {
    const updatedOptions = { tags: [tag] };
    const updatedFilterValues = getUpdatedFilterValues({ updatedOptions });
    handleAccordionFieldChange(getFilterId('tags'));

    handleFetchAndUpdateFilters({ updatedFilterValues });
  }

  function clearFilters() {
    setOptions(emptyOptions);
    collapseAccordion();
  }

  function clearAllFilters({ shouldFetch = true }) {
    handleFetchAndUpdateFilters({ updatedFilterValues: emptyState, shouldFetch });
    isFunction(clearParams) && clearParams([SEARCH_QUERY_PARAM]);
    collapseAccordion();
  }

  return {
    filterValues,
    setSearchValue,
    setOptions,
    setSearchAndOptions,
    handleFilterChange,
    handleTagClick,
    clearFilters,
    clearAllFilters,
    onFetchData,
  };
}

export default useFilterValues;
