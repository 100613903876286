import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

function getCardHeightStyle(cardRef, topCardHeight) {
  if (cardRef && cardRef.current) {
    const { height } = cardRef.current.getBoundingClientRect() || {};

    if (height && height > topCardHeight) {
      return { maxHeight: height - topCardHeight };
    }
  }

  return {};
}

function useCardHeight({ cardRef, data, topCardHeight = 270 }) {
  const [heightStyle, setHeightStyle] = useState({});
  const [isSort, setIsSort] = useState(false);

  useEffect(() => {
    if (!isEmpty(data) && !isSort) {
      setHeightStyle(getCardHeightStyle(cardRef, topCardHeight));
    }
  }, [data, isSort, cardRef]);

  return {
    heightStyle,
    isSort,
    setIsSort,
  };
}

export default useCardHeight;
