import React, { lazy, Suspense } from 'react';
import { render } from 'react-dom';

export const suspenseComponent = (id, importStatement, componentProps) => {
  const mountElement = document.getElementById(id);

  if (mountElement) {
    const Component = lazy(importStatement);
    const { dataset } = mountElement || {};

    render(
      <Suspense fallback={null}>
        <Component {...(componentProps && componentProps(dataset || {}) || {})} />
      </Suspense>,
      mountElement,
    );
  }
};
