import { useEffect, useState } from 'react';

import tableSort from 'components/core/Table/utils/tableSort';
import sortDirections from 'utils/constants/sortDirections';

const { DESC } = sortDirections;
const defaultSortData = {
  direction: undefined,
  column: undefined,
};

function useSortTable(sort = {}, isLoading = false) {
  const { sort: sortBy, direction: sortDirection, toReset = false } = sort;
  const [sortData, setSortData] = useState(defaultSortData);

  function getDirection(column) {
    const direction = (sortData.column !== column)
      ? DESC
      : tableSort.toggleSort(sortData.direction);

    setSortData({ direction, column });

    return direction;
  }

  function resetSortData() {
    setSortData(defaultSortData);
  }

  /** Fix reset sort from redux state */
  useEffect(() => {
    (sortBy || sortDirection) && setSortData({ column: sortBy, direction: sortDirection });
    (!isLoading && toReset) && resetSortData();
  }, [sortBy, sortDirection, toReset, isLoading]);

  return {
    sortData,
    setSortData,
    getDirection,
    resetSortData,
  };
}

export default useSortTable;
