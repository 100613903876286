import { isEmpty, toLower } from 'lodash';

import {
  DEMOCRAT_PARTY_SHORT,
  REPUBLICAN_PARTY_SHORT,
  PARTY_SLUG_SHORT,
} from './constants/parties';

export const isDemocrat = partyShort => toLower(partyShort) === DEMOCRAT_PARTY_SHORT;
export const isRepublican = partyShort => toLower(partyShort) === REPUBLICAN_PARTY_SHORT;

/**
 * This function receives a string (that should be either the short or the slug of the party) and returns an object with the suitable pair of party short and slug.
 *
 * @param {string} string party slug/short
 * @return {object} object with slug and short values
 * @example
 * ```js
 *  getPartyBadge('d')
 * >> {
 *      partyShort: 'd',
 *      partySlug: 'democratic',
 *    }
 * ```
 */
export const getPartyBadge = (slugOrShort) => {
  const slugOrShortToCompare = toLower(slugOrShort)[0];
  const partyBagde = PARTY_SLUG_SHORT.find(
    ({ short }) => (short === slugOrShortToCompare)
  );

  if (isEmpty(slugOrShort) || isEmpty(partyBagde)) return {};

  return {
    partyShort: partyBagde.short,
    partySlug: partyBagde.slug,
  };
};
