export const joinComponentWithSeparator = (separator = ', ') =>
  (accumulator, current) => [
    ...accumulator,
    accumulator.length ? separator : '',
    current,
  ];

const joinComponents = listComponents =>
  listComponents.reduce(joinComponentWithSeparator(), []);

export default joinComponents;
