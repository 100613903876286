import { useEffect, useRef } from 'react';

const useMountedRef = () => {
  const mountedRef = useRef(true);

  useEffect(() => () => { mountedRef.current = false; }, []);

  return mountedRef.current;
};

export default useMountedRef;
