// Deprecated symbol, consider using key instead
const KEY_CODES = {
  BACKSPACE: 8,
  ENTER: 13,
  DEL: 46,
  A: 65,
  ONE: 49,
  TAB: 9,
  MINUS: 189,
  ESCAPE: 27,
};

export default KEY_CODES;
