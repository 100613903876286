import { useEffect, useState } from 'react';

import { getCurrentUser } from 'utils/currentUser';

import useMediaQuery from './useMediaQuery';
import {
  largeNavHeaderHeight,
  mediumHeaderHeight,
  mobileHeaderHeight,
  medium,
  xLarge,
  topBarHeight,
  navV2Height,
} from './settings.scss';

export default function usePageDimentions() {
  const [pageHeight, setPageHeight] = useState(window.innerHeight);
  const { width } = useMediaQuery();
  const parse = number => parseInt(number, 10);
  const parsedMedium = parse(medium);
  const parsedLarge = parse(xLarge);
  const productBarHeight = getCurrentUser().hasP2aAccount ? parse(topBarHeight) : 0;

  const getNavigationHeight = () => {
    if (width <= parsedMedium) return parse(mobileHeaderHeight);

    if (gon.isNavigationV2Enabled) {
      return parse(navV2Height);
    }

    if (width <= parsedLarge) {
      return parse(mediumHeaderHeight);
    }

    return parse(largeNavHeaderHeight);
  };

  const updatePageHeight = () => {
    setPageHeight(window.innerHeight);
  };

  const [navigationHeight, setNavigationHeight] = useState(getNavigationHeight());

  useEffect(() => {
    const height = getNavigationHeight();

    setNavigationHeight(height);
  }, [width]);

  useEffect(() => {
    window.addEventListener('resize', updatePageHeight);

    return () => window.removeEventListener('resize', updatePageHeight);
  }, []);

  const contentHeight = pageHeight - navigationHeight - productBarHeight;

  return {
    navigationHeight,
    pageHeight,
    pageWidth: width,
    productBarHeight,
    contentHeight,
  };
}
