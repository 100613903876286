import QueryString from 'query-string';

const OPTIONS = {
  // => foo[]=1&foo[]=2&foo[]=3
  arrayFormat: 'bracket',
};

export const queryParse = (queryString, paramName) => {
  const parsedParams = QueryString.parse(queryString, OPTIONS);

  if (!paramName) return parsedParams;

  return parsedParams[paramName];
};

export const queryParser = (params, type) => QueryString.parse(params, type || OPTIONS);

export const queryStringify = (params, type) =>
  QueryString.stringify(params, type || OPTIONS);
