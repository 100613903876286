import moment from 'moment';
import PropTypes from 'prop-types';

import { subscriptionShape } from 'components/Dashboard/utils/shapes';
import { workspaceShape } from 'redux/events/utils/shapes';
import { labelValueShape, stringNumberType } from 'utils/shapes';

export const billStatusTypeShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isFailed: PropTypes.bool,
  pdfStyle: PropTypes.shape({ left: PropTypes.number }),
});

export const congressOptionShape = PropTypes.shape({
  value: PropTypes.string,
  label: PropTypes.string,
});

export const statusOptionShape = PropTypes.shape({
  value: PropTypes.string,
  label: PropTypes.string,
});

export const billsUiShape = PropTypes.shape({
  isLoading: PropTypes.bool,
  isLoadingMyFeedDocuments: PropTypes.bool,
  isLoadingSubscriptions: PropTypes.bool,
  isLoadingTags: PropTypes.bool,
});

const commonSponsorPropTypes = {
  id: stringNumberType,
  name: PropTypes.string,
};

export const commonGraphSponsorPropTypes = {
  ...commonSponsorPropTypes,
  count: PropTypes.number,
  party: PropTypes.string,
};

const billSponsorShape = PropTypes.shape({
  ...commonSponsorPropTypes,
  partyShort: PropTypes.string,
  partySlug: PropTypes.string,
  url: PropTypes.string,
});

export const commonRegulationAndBillPropTypes = {
  documentType: PropTypes.string,
  id: stringNumberType,
  index: PropTypes.number,
  itemType: PropTypes.string,
  lastActionDate: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]),
  page: stringNumberType,
  recordId: stringNumberType,
  recordType: PropTypes.string,
  tags: PropTypes.arrayOf(labelValueShape),
  withTrack: PropTypes.bool,
  sharings: PropTypes.arrayOf(workspaceShape),
};

export const commonBillPropTypes = {
  ...commonRegulationAndBillPropTypes,
  cosponsors: PropTypes.arrayOf(billSponsorShape),
  sponsors: PropTypes.arrayOf(billSponsorShape),
  subscription: subscriptionShape,
  allCosponsorsSize: PropTypes.number,
  billDescription: PropTypes.string,
  billId: PropTypes.string,
  billSummary: PropTypes.string,
  billSummaryLastUpdated: PropTypes.string,
  billTextLink: PropTypes.string,
  billTitle: PropTypes.string,
  billUrl: PropTypes.string,
  govPredictLink: PropTypes.string,
  hasVote: PropTypes.string,
  lastAction: PropTypes.string,
  status: PropTypes.string,
  snippet: PropTypes.string,
};

export const billShape = PropTypes.shape(commonBillPropTypes);
