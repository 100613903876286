import { useState, useEffect } from 'react';
import { map, difference, find, filter, concat, includes, isEmpty } from 'lodash';

import { ALL_ACTIVE, ALL_INACTIVE } from 'utils/constants/billStatus';

function useBillStatusOptions({ billStatuses, billStatusOptions, inactiveOptions }) {
  const [filteredOptions, setFilteredOptions] = useState(billStatusOptions);

  const resetFilteredOptions = () => setFilteredOptions(billStatusOptions);

  const allOptionValues = map(billStatusOptions, 'value');
  const allInactivePersistOptions = [ALL_INACTIVE, ...inactiveOptions];
  const allActivePersistOptions = difference(allOptionValues, allInactivePersistOptions);

  const filterCategory = (optionValue, persistValues) => {
    const isAllOptionSelected = !isEmpty(
      find(billStatuses, { value: optionValue })
    );

    if (isAllOptionSelected) {
      const allBillStatusValues = map(billStatuses, 'value');
      const persistOptions = concat(allBillStatusValues, persistValues);

      setFilteredOptions(prevOptions => filter(
        prevOptions,
        ({ value }) => includes(persistOptions, value),
      ));
    }
  };

  const filterBillStatusOptions = () => {
    resetFilteredOptions();

    filterCategory(ALL_ACTIVE, allInactivePersistOptions);
    filterCategory(ALL_INACTIVE, allActivePersistOptions);
  };

  useEffect(filterBillStatusOptions, [billStatuses]);

  return filteredOptions;
}

export default useBillStatusOptions;
