import { partialRight, groupBy, flatMap, map, sortBy } from 'lodash';

function sortByDisplayOrder(items) {
  return sortBy(items, 'displayOrder');
}

const groupByType = partialRight(groupBy, 'type');

const addIndexToUrl = (url, index, urlsFromType) => (
  urlsFromType.length > 1 ? { ...url, type: `${url.type} ${index + 1}` } : url
);

const formatUrls = (urls) => {
  const groupedUrls = groupByType(urls);

  const formattedUrls = flatMap(groupedUrls, urlsFromType => map(urlsFromType, addIndexToUrl));

  return sortByDisplayOrder(formattedUrls);
};

export function transformExecutive(executive, role = '') {
  const {
    militaryService,
    membership,
    education,
    politicalHistory,
    urls,
    publications,
    awards,
    positions,
  } = executive;

  return {
    ...executive,
    militaryService: sortByDisplayOrder(militaryService),
    membership: sortByDisplayOrder(membership),
    education: sortByDisplayOrder(education),
    politicalHistory: sortByDisplayOrder(politicalHistory),
    urls: formatUrls(urls),
    publications: sortByDisplayOrder(publications),
    awards: sortByDisplayOrder(awards),
    positions: sortBy(positions, ({ roleType }) => (roleType === role ? 0 : 1)),
  };
}
