import { lazy } from 'react';

const DID_RETRY_RELOAD_KEY = 'did_retry_reload';

/**
 * This function receives a function that imports a component. If the import
 * fails, the window will reload and a control variable will be set in
 * localStorage to ensure that the reload won't be fired more than one time.
 *
 * @param {function} importFn - function that returns an import
 */
export function lazyRetry(importFn) {
  const retryImporter = () => new Promise((resolve, reject) =>
    importFn()
      .then((...args) => {
        localStorage.removeItem(DID_RETRY_RELOAD_KEY);
        resolve(...args);
      })
      .catch((error) => {
        if (localStorage.getItem(DID_RETRY_RELOAD_KEY)) {
          reject(error);
          return;
        }

        localStorage.setItem(DID_RETRY_RELOAD_KEY, true);
        location.reload();
      }));

  return lazy(retryImporter);
}
