import { map, compact, join, capitalize as _capitalize } from 'lodash';

export function pluralize(singularWord, pluralWord, count) {
  if (count === 1) {
    return singularWord;
  }

  return pluralWord;
}

export const joinTerms = (terms, options) => {
  const defaultOptions = {
    separator: ',',
    conjunction: 'or',
  };

  const { separator, conjunction } = {
    ...defaultOptions,
    ...options,
  };

  if (terms.length === 0) {
    return '';
  }

  // ex. David
  if (terms.length === 1) {
    return terms[0];
  }

  // ex. David or Matthias
  if (terms.length === 2) {
    return terms.join(` ${conjunction} `);
  }

  // ex. David, Matthias, or Saulo
  const firstTerms = terms.slice(0, -1);
  const lastTerm = terms.slice(-1)[0];

  const joinWithSeparator = (...items) => items.join(`${separator} `);
  const joinWithConjunction = (...items) => items.join(`${separator} ${conjunction} `);

  return joinWithConjunction(
    joinWithSeparator(...firstTerms),
    lastTerm,
  );
};

/**
 * <[^>]+> Match content inside tags
 * &([a-z0-9]+|#[0-9]{1,6}|#x[0-9a-f]{1,6}); Match html special entities e.g: &amp;
 */
export const HTML_ELEMENTS_REGEX = /<[^>]+>|&([a-z0-9]+|#[0-9]{1,6}|#x[0-9a-f]{1,6});/g;

// Match new line chars
export const NEW_LINE_REGEX = /\r?\n|\r/g;

export const NON_PRINTABLE_ASCII = /[^ -~]+/g;

/**
 * Remove html elements and replace new line chars
 * with a space
 */
export function removeHTMLElements(text) {
  if (!text) {
    return '';
  }

  return text
    .replace(HTML_ELEMENTS_REGEX, '')
    .replace(NEW_LINE_REGEX, ' ');
}

export function isJSON(text) {
  try {
    JSON.parse(text);
  } catch (e) {
    return false;
  }

  return true;
}

export const joinCompact = (terms, separator = ', ') => join(compact(terms), separator);

export const humanize = (text = '', options = {}) => {
  const titleizeString = (str) => {
    const words = str.split(' ');

    return map(words, _capitalize).join(' ');
  };

  const {
    capitalize = true,
    titleize = false,
  } = options;

  const cleanText = text.replace(/_/g, ' ');

  if (titleize) {
    return titleizeString(cleanText);
  }

  if (capitalize) {
    return _capitalize(cleanText);
  }

  return cleanText;
};

export const getParenthesisItem = item => (item ? `(${item})` : '');
