export const REPUBLICAN_PARTY = 'republican';
export const REPUBLICAN_PARTY_SHORT = 'r';
export const DEMOCRAT_PARTY = 'democrat';
export const DEMOCRAT_PARTY_SHORT = 'd';
export const INDEPENDENT = 'independent';
export const INDEPENDENT_SHORT = 'i';
export const NONPARTISAN = 'nonpartisan';
export const NONPARTISAN_SHORT = 'n';
export const OTHER = 'other';
export const OTHER_SHORT = 'o';

export const PARTY_SLUG_SHORT = [
  {
    short: REPUBLICAN_PARTY_SHORT,
    slug: REPUBLICAN_PARTY,
  },
  {
    short: DEMOCRAT_PARTY_SHORT,
    slug: DEMOCRAT_PARTY,
  },
  {
    short: INDEPENDENT_SHORT,
    slug: INDEPENDENT,
  },
  {
    short: NONPARTISAN_SHORT,
    slug: NONPARTISAN,
  },
];

const PARTIES = [
  {
    value: REPUBLICAN_PARTY,
    label: 'Republican',
  },
  {
    value: DEMOCRAT_PARTY,
    label: 'Democrat',
  },
  {
    value: INDEPENDENT,
    label: 'Independent',
  },
  {
    value: OTHER,
    label: 'Other',
  },
];

export const PARTY_OPTIONS = {
  R: 'Republican',
  D: 'Democratic',
  I: 'Independent',
  O: 'Other',
};

export default PARTIES;
