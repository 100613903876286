import { useEffect, useState } from 'react';

function useWindowBreakpoint() {
  const [breakpoint, setBreakpoint] = useState(window.innerWidth);

  function updateWindowDimensions() {
    setBreakpoint(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', updateWindowDimensions);

    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  return breakpoint;
}

export default useWindowBreakpoint;
