/**
 * Returns a formatted string number, example:
 * input: 123456789
 * output: 123,456,789
 */
export const formatNumber = number => (
  parseInt(number, 10)
    .toLocaleString('en-US', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
);

export const formatNumberBy = ({
  number,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2,
}) => {
  const parsedNumber = parseFloat(number);
  const numberToFormat = !isNaN(parsedNumber) ? parsedNumber : 0;

  return numberToFormat
    .toLocaleString('en-US', {
      style: 'decimal',
      minimumFractionDigits,
      maximumFractionDigits,
    });
};

export const currencyFormat = (number, minimumFractionDigits = 0, maximumFractionDigits = 2) => {
  const parsedFloat = parseFloat(number);
  const numberToFormat = !isNaN(parsedFloat) ? parsedFloat : 0;

  return numberToFormat.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits,
    maximumFractionDigits,
  });
};

export const formatMoney = num => (num).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

export function formatUnacceptedChars(string = '') {
  /** Replacing all unaccepted characters by regex */
  const unacceptedCharactersRegex = /[.*+?^${}()|[\]\\]/g;
  const lastMatch = '\\$&';
  const formattedString = string.replace(unacceptedCharactersRegex, lastMatch);

  return new RegExp(`(${formattedString})`, 'gi');
}

export function trimStringSpaces(string = '', removeAllSpaces = false) {
  if (removeAllSpaces) {
    return string.replace(/\s+/g, '');
  }

  return string.replace(/\s+/g, ' ');
}
