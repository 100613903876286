import { useEffect, useState } from 'react';

function useUIPaginator({
  allItems = [],
  onLoadingInit = false,
  isLoading,
  pageSize = 5,
}) {
  const [isLoadingItems, setIsLoadingItems] = useState(true);
  const [items, setItems] = useState([]);
  const [paginator, setPaginator] = useState({});

  function setInitPaginator() {
    setItems(allItems.slice(0, pageSize));
    setPaginator({
      current: 1,
      totalCount: allItems.length,
      total: Math.ceil(allItems.length / pageSize),
      pageSize,
    });

    !isLoading && setIsLoadingItems(false);
  }

  /** Apply when all items is changed  - default behaviour */
  useEffect(() => {
    !onLoadingInit && setInitPaginator();
  }, [allItems]);

  /** Apply only when onLoading is changing - to avoid infinite call */
  useEffect(() => {
    onLoadingInit && setInitPaginator();
  }, [onLoadingInit, isLoading]);

  function handleChangePaginator(page = 1) {
    const indexMin = (page - 1) * pageSize;
    const indexMax = indexMin + pageSize;

    const filteredItems = allItems.filter((_, index) => index >= indexMin && index < indexMax);
    setItems(filteredItems);
    setPaginator(prevProps => ({
      ...prevProps,
      current: page,
    }));
  }

  return {
    isLoadingItems,
    items,
    pageSize,
    paginator,
    setIsLoadingItems,
    onChangePaginator: handleChangePaginator,
  };
}

export default useUIPaginator;
