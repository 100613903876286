import moment from 'moment';
import isObject from 'lodash/isObject';

import { PHONE_REGEXP } from 'utils/constants';

const defaultErrorMessages = {
  required: 'Can\'t be blank',
  email: 'Invalid email address',
  validDate: 'Is invalid',
  zipcode: 'Is invalid',
  phone: 'Is invalid',
};

export const EMAIL_REGEXP = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const ZIPCODE_REGEXP = /^\d{5}(-\d{4})?$/i;

export const required = (value) => {
  if (value && !(Array.isArray(value) && value.length === 0)) {
    return undefined;
  }

  return { required: defaultErrorMessages.required };
};

export const email = (value) => {
  if (value && EMAIL_REGEXP.test(value)) {
    return undefined;
  }

  return { email: defaultErrorMessages.email };
};

export const phone = (value) => {
  if (value && PHONE_REGEXP.test(value)) {
    return undefined;
  }

  return { phone: defaultErrorMessages.phone };
};

export const validDate = (value) => {
  if (moment(value).isValid()) {
    return undefined;
  }

  return { validDate: defaultErrorMessages.validDate };
};

export const zipcode = (value) => {
  if (ZIPCODE_REGEXP.test(value)) {
    return undefined;
  }

  return { zipcode: defaultErrorMessages.zipcode };
};

export function errorText(error, customMessages = {}) {
  const validatorName = Object.keys(error)[0];
  const customMessage = customMessages[validatorName];

  return !isObject(error) && error || customMessage || error[validatorName];
}
