import isEmpty from 'lodash/isEmpty';

const defaultOptions = {
  block: 'center',
};

const scrollIntoViewElementById = (id = '', options = defaultOptions) => {
  const element = document.getElementById(id);

  if (isEmpty(element)) return;

  element.scrollIntoView(options);
};

export default scrollIntoViewElementById;
