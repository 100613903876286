import React, { Component } from 'react';

function withStates(WrappedComponent) {
  return class extends Component {
    render() {
      const props = this.props;
      // @TODO: Replace with API endpoint
      const states = gon.mappyStates;
      const allStates = states.map(state => ({
        ...state,
        value: state.token,
      }));

      return <WrappedComponent states={allStates} {...props} />;
    }
  };
}

export default withStates;
