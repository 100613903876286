import { isEmpty, omitBy } from 'lodash';

import { track } from 'utils/tracking';
import { FEDERAL_AGENCY_STAFFERS_SLUG } from 'utils/constants/featureSlugs';
import { resultCategories } from 'components/GlobalSearch/utils/constants/resultCategories';
import { resultLabels } from 'components/GlobalSearch/utils/constants/resultLabels';
import { containsAdvancedOperators } from 'components/core/CheatSheet/advancedOperators';

import {
  GLOBAL_SEARCH_QUICK_SEARCH_CONTEXT,
  GLOBAL_SEARCH_ADVANCED_SEARCH_CONTEXT,
} from './contexts';

function findItemInResults(results, cardLabel, cardUrl) {
  const item = results.find(({ url }) => url === cardUrl);

  if (!isEmpty(item)) {
    return {
      item,
      rank: results.findIndex(({ url }) => url === cardUrl) + 1,
    };
  }

  return {
    item: results.find(({ label }) => label === cardLabel),
    rank: results.findIndex(({ label }) => label === cardLabel) + 1,
  };
}

function trackQuickSearch(event, data = {}) {
  track(event, GLOBAL_SEARCH_QUICK_SEARCH_CONTEXT, data);
}

function trackAdvancedSearch(event, data = {}) {
  track(event, GLOBAL_SEARCH_ADVANCED_SEARCH_CONTEXT, data);
}

export function trackAdvancedNewSearch(term, searchParams) {
  const usesAdvancedOperators = containsAdvancedOperators(term);
  const filters = omitBy(searchParams, isEmpty);

  trackAdvancedSearch('new search', { term, filters, usesAdvancedOperators });
}

export function trackAdvancedSearchResults(searchParams, term, results = [], cardLabel, action, cardUrl) {
  const { item = {}, rank } = findItemInResults(results, cardLabel, cardUrl);
  const { url, slug, label } = item;

  if (isEmpty(item)) {
    return;
  }

  const filters = omitBy(searchParams, isEmpty);
  const category = resultCategories[slug];
  const usesAdvancedOperators = containsAdvancedOperators(term);
  const itemUrl = FEDERAL_AGENCY_STAFFERS_SLUG === slug ? cardUrl : url;

  trackAdvancedSearch('result found', {
    term,
    filters,
    label,
    rank,
    link: itemUrl,
    type: slug,
    category,
    total: results.length,
    usesAdvancedOperators,
    action,
  });
}

export function trackClearedAll(data) {
  trackQuickSearch('recent visits - cleared all', data);
}

export function trackAbandonedSearch(action, term = '', total = 0) {
  trackQuickSearch('abandoned search', { term, action, total });
}

export function trackRecentResultFound(result = {}, index, total) {
  const { url = '', strategy = '', label = '' } = result;

  const data = {
    label,
    link: url,
    type: resultLabels[strategy],
    category: resultCategories[strategy],
    rank: index + 1,
    total,
  };

  trackQuickSearch('recent visits - result found', data);
}

export function trackGotoAdvancedSearch(term = '', total = 0) {
  trackQuickSearch('goto advanced search', { term, total });
}

export function trackResultFound(term, selectedOption, options) {
  const data = {
    term,
    label: selectedOption.label,
    link: selectedOption.url,
    type: resultLabels[selectedOption.strategy],
    category: resultCategories[selectedOption.strategy],
    rank: options.findIndex(x => x.url === selectedOption.url) + 1,
    total: options.length,
  };

  trackQuickSearch('result found', data);
}

export function trackQuickLinksResults(label, link, total) {
  trackQuickSearch('quick links - result found', { label, link, total });
}
