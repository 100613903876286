import anchorme from 'anchorme';
import { isEmpty, isString, size } from 'lodash';

import { BASE_URL } from 'utils/constants';
import { URL_REGEXP } from 'utils/constants/regexps';

export const getUrlWithBase = (url) => {
  if (isEmpty(url) || !isString(url)) {
    return '';
  }

  if (url[0] === '/') {
    return `${BASE_URL}${url}`;
  }

  return `${BASE_URL}/${url}`;
};

export const getUrlHostname = (url) => {
  try {
    const { host } = anchorme.list(url)[0];
    const matchedHostname = host.match(/^www\.(.*)$/);
    const hostnameWithoutWww = size(matchedHostname) > 0 && matchedHostname[1];

    return hostnameWithoutWww || host;
  } catch (e) {
    return null;
  }
};

export const isExternal = url => RegExp(`^((f|ht)tps?:)?//(?!${window.location.host})`).test(url);

export const isOnPathname = url => RegExp(`^${url}`).test(window.location.pathname);

export const isURL = str => (str && URL_REGEXP.test(str));

export const normalizeUrl = url => url.replace(/([^:]\/)\/+/g, '$1');

export const absoluteUrl = url => (
  isURL(url) ? url : getUrlWithBase(url)
);
