import isEmpty from 'lodash/isEmpty';

export const fuzzyMatch = (value, content) => {
  if (isEmpty(value)) {
    return true;
  }

  if (isEmpty(content)) {
    return false;
  }

  const [v, ...restValue] = value;
  const [c, ...restContent] = content;

  if (v.toLowerCase() === c.toLowerCase()) {
    return fuzzyMatch(restValue, restContent);
  }

  return fuzzyMatch(value, restContent);
};
