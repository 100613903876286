import React, { Suspense } from 'react';

function withLazy(Component) {
  return props => (
    <Suspense fallback={null}>
      <Component {...props} />
    </Suspense>
  );
}

export default withLazy;
