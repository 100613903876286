import { track } from '.';
import { WORKSPACES_CONTEXT } from './contexts';

export function trackWorkspacesEvent(eventName, data = null) {
  track(eventName, WORKSPACES_CONTEXT, data);
}

export function trackResultsFiltered(field, value) {
  trackWorkspacesEvent('results filtered', { filterType: field, value });
}
