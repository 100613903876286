import moment from 'moment';
import { isEmpty } from 'lodash';

import {
  CALENDAR_DATE_FORMAT,
  YEAR_DATE_FORMAT,
  GLOBAL_DATE_FORMAT,
  MONTH_DAY_YEAR_DATE_FORMAT,
  FULL_DATE_FORMAT,
  SHORT_TIME_FORMAT,
} from './constants/date';

export const format = date => date && date.format();

export const getDate = date => (isEmpty(date) ? date : moment(date));

export const currentYear = () => moment().year();

export const formatDate = date => date && moment(date).format(CALENDAR_DATE_FORMAT);

export const formatDateBy = (date, formatValue) => date && moment(date).format(formatValue);

export const formatGlobalUtcDate = date => (date ? moment.utc(date).format(GLOBAL_DATE_FORMAT) : '');

export const formatGlobalDate = date => (date ? moment(date).format(GLOBAL_DATE_FORMAT) : '');

export const formatMonthDayYear = date => formatDateBy(date, MONTH_DAY_YEAR_DATE_FORMAT);

export const formatFullUtcDate = date => date && moment.utc(date).format(FULL_DATE_FORMAT);

export const formatFullDate = date => date && moment(date).format(FULL_DATE_FORMAT);

export const formatTime = time => time && moment(time).format(SHORT_TIME_FORMAT);

export const getDateFromYearsAgo = (years = 10) => moment().subtract(years, 'years');

export const currentDate = formatValue => moment().format(formatValue || FULL_DATE_FORMAT);

export const utcDayEnd = date => moment(date)
  .hour(23)
  .minute(59)
  .second(59)
  .toISOString();

export const utcDayStart = date => moment(date)
  .hour(0)
  .minute(0)
  .second(0)
  .toISOString();

export function combineDateTime(date, time) {
  if (isEmpty(time)) throw new Error("Due Time can't be empty!");
  if (isEmpty(date)) throw new Error("Due Date can't be empty!");

  return moment(date.concat(' ', time), 'YYYY-MM-DD hh:mm A').format();
}

export function generateYearsRange(start, end) {
  const fromDate = moment(start);
  const toDate = moment(end);
  const type = 'year';

  // Includes current year in the range
  const rangeLength = moment(toDate).diff(moment(fromDate), type) + 1;

  return Array(rangeLength)
    .fill(null)
    .map((_, index) => moment(start)
      .add(index, type)
      .format(YEAR_DATE_FORMAT))
    .reverse();
}
