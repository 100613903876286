import { map, filter, isEmpty, keys, forEach, every } from 'lodash';

import { loadAdvancedSearch } from 'redux/advancedSearch/utils';

import { getIsAccordionFilterToOpen } from './utils';

function useFeedAdvancedSearch({
  type,
  transformOptionsForUI,
  onAccordionToggle,
  setOptions,
  setSearchAndOptions,
  search,
  searchId,
  setSearchValue,
  onFetchData,
  filters,
  emptyOptions,
  fetchKeywords,
  setShowRemovedKeywords,
}) {
  function handleRemovedKeywords(keywords) {
    fetchKeywords().then(({ payload }) => {
      const allKeywords = map(payload, 'token');
      const showRemovedKeywords = !every(keywords, keyword => allKeywords.includes(keyword));
      setShowRemovedKeywords(showRemovedKeywords);
    });
  }

  function handleOpenSelectedFilters(searchOptions) {
    const { keywords } = searchOptions;
    !isEmpty(keywords) && handleRemovedKeywords(keywords);

    const getAccordionKeysToOpen = key => map(filter(
      filters,
      getIsAccordionFilterToOpen({ key, searchOptions, emptyOptions })
    ), 'id');

    forEach(keys(searchOptions), key => (
      onAccordionToggle(getAccordionKeysToOpen(key))
    ));
  }

  function restoreSearchResults(searchData) {
    const searchOptions = transformOptionsForUI({ type, searchData });
    const isPreviousSearch = true;

    handleOpenSelectedFilters(searchOptions);

    isEmpty(search)
      ? setOptions(searchOptions, isPreviousSearch)
      : setSearchAndOptions(searchOptions, search, isPreviousSearch);
  }

  function fetchPreviousSearch() {
    loadAdvancedSearch(searchId)
      .then(restoreSearchResults)
      .catch((error) => {
        isEmpty(search) ? onFetchData() : setSearchValue(search);

        throw error;
      });
  }

  function handleInitialLoad() {
    if (searchId) {
      return fetchPreviousSearch();
    }

    if (!isEmpty(search)) {
      return setSearchValue(search);
    }

    return onFetchData();
  }

  return { handleInitialLoad, handleOpenSelectedFilters };
}

export default useFeedAdvancedSearch;
