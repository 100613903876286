import { track } from './index';
import {
  trackBillsFiltered,
  trackBillsSorted,
  trackBillVisited,
  trackBillSponsorVisited,
  trackBillCosponsorVisited,
  trackBillsPageVisited,
} from './billsTable';

export function trackVotingRecordFiltered(context, filter, input) {
  track('voting record - results filtered', context, { filter, input });
}

export function trackVotingRecordSorted(context, column, direction) {
  track('voting record - results sorted', context, { column, direction });
}

export function trackVotingRecordVoteVisited(context, vote) {
  track('voting record - vote visited', context, { vote });
}

export function trackVotingRecordIssueVisited(context, issue, vote) {
  track('voting record - issue visited', context, { issue, vote });
}

export function trackVotingRecordPageVisited(context, page) {
  track('voting record - page visited', context, { page });
}

export function trackLegislatorsBillsFiltered(context, filter, input) {
  trackBillsFiltered(`${context} - legislators bills`, filter, input);
}

export function trackLegislatorsBillsSorted(context, column, direction) {
  trackBillsSorted(`${context} - legislators bills`, column, direction);
}

export function trackLegislatorsBillVisited(context, billId) {
  trackBillVisited(`${context} - legislators bills`, billId);
}

export function trackLegislatorsBillSponsorVisited(context, legislator) {
  trackBillSponsorVisited(`${context} - legislators bills`, legislator);
}

export function trackLegislatorsBillCosponsorVisited(context, legislator) {
  trackBillCosponsorVisited(`${context} - legislators bills`, legislator);
}

export function trackLegislatorsBillsPageVisited(context, page) {
  trackBillsPageVisited(`${context} - legislators bills`, page);
}

export const trackCongressionalStafferEmailed = context => (staffer) => {
  track('congressional staffers - staffer emailed', context, { staffer });
};

export const trackCongressionalStafferVisited = context => (staffer) => {
  track('congressional staffers - staffer visited', context, { staffer });
};

export const trackCongressionalStaffersPageVisited = context => (page) => {
  track('congressional staffers - page visited', context, { page });
};

export const trackCongressionalStaffersResultsSorted = context => (column, direction) => {
  track('congressional staffers - results sorted', context, { column, direction });
};

export const trackStateStafferEmailed = context => (staffer) => {
  track('state staffers - staffer emailed', context, { staffer });
};

export const trackStateStafferVisited = context => (staffer) => {
  track('state staffers - staffer visited', context, { staffer });
};

export const trackStateStafferPhoned = context => (staffer) => {
  track('state staffers - staffer phoned', context, { staffer });
};

export const trackStateStaffersPageVisited = context => (page) => {
  track('state staffers - page visited', context, { page });
};

export const trackStateStaffersResultsSorted = context => (column, direction) => {
  track('state staffers - results sorted', context, { column, direction });
};

export const trackStakeholderEmailed = context => (stakeholder) => {
  track('stakeholders - stakeholder emailed', context, { stakeholder });
};

export const trackStakeholderVisited = context => (stakeholder) => {
  track('stakeholders - stakeholder visited', context, { stakeholder });
};

export const trackStakeholderPhoned = context => (stakeholder) => {
  track('stakeholders - stakeholder phoned', context, { stakeholder });
};

export const trackStakeholdersPageVisited = context => (page) => {
  track('stakeholders - page visited', context, { page });
};

export const trackStakeholderResultsSorted = context => (column, direction) => {
  track('stakeholders - results sorted', context, { column, direction });
};
