import { exportBlob } from 'utils/fileExport';

export const GOV_REPORTS_URL = '/gov_reports';

export const downloadGovReports = async ({ data }) => {
  const fileName = data.strategy_inputs.filename;
  const fileExtension = data.strategy_inputs.file_extension;

  const ajax = $.ajax({
    data,
    url: GOV_REPORTS_URL,
    method: 'POST',
    dataType: 'native',
    xhrFields: {
      responseType: 'blob',
    },
  });

  const responseData = await Promise.resolve(ajax);

  return exportBlob(responseData, fileName, fileExtension);
};
