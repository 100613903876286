import React from 'react';
import PropTypes from 'prop-types';
import TippyTooltip from '@tippyjs/react';

import styles from './styles.module.scss';

function withDisabledStateTooltip(Component) {
  const ComponentWithTooltip = ({
    disabled,
    tooltipContent,
    ...restProps
  }) => {
    const componentContent = (
      <Component
        {...restProps}
        disabled={disabled}
      />
    );

    if (disabled) {
      return (
        <TippyTooltip
          content={tooltipContent}
          placement="bottom"
          theme="dark"
        >
          <div className={styles.tooltipTrigger}>
            {componentContent}
          </div>
        </TippyTooltip>
      );
    }

    return componentContent;
  };

  ComponentWithTooltip.propTypes = {
    disabled: PropTypes.bool,
    tooltipContent: PropTypes.node,
  };

  return ComponentWithTooltip;
}


export default withDisabledStateTooltip;
