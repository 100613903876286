import { INTEL_CONTEXT } from './intel/contexts';
import { PLATFORM_HELP_CONTEXT } from './contexts';

export * from './dashboard';
export * from './hillday';
export * from './grassroots';
export * from './publicFacingTools';
export * from './moneyball';
export * from './intel';

const EVENT_MAX_LENGTH = 100;

/**
 * To companies on tracking blacklist we use an mock object instead of segment lib,
 * so if you want to use addittional lib methods please check the mock code (app/views/layouts/tracking/_no_tracking.erb)
 */
export function track(eventName, context, data = {}) {
  return Promise.resolve()
    .then(() => {
      const event = `${context} - ${eventName}`;
      if (event.length > EVENT_MAX_LENGTH) {
        throw Error(`Event string length should be lesser than ${EVENT_MAX_LENGTH}`);
      }
    })
    .catch((error) => {
      console.error(error);
      window.Sentry.captureException(error);
    });
}

export function trackSavedToTopic(context) {
  track('saved to topic', context);
}

export function trackKeywordAlertItemSavedToTopic(context, topics) {
  track('keyword alert item saved to topic', context, { topics });
}

export function trackGlobalSearchAllToggled(toggle, context = INTEL_CONTEXT) {
  track('all toggled', `${context} - global search`, { toggle: toggle ? 'on' : 'off' });
}

export function trackGlobalSearchItemToggled(dataType, toggle, context = INTEL_CONTEXT) {
  track('item toggled', `${context} - global search`, { dataType, toggle: toggle ? 'on' : 'off' });
}

export function trackGlobalSearchSearched({ inputId }) {
  const input = document.getElementById(inputId);
  const keywords = input && input.value;

  track('searched', `${INTEL_CONTEXT} - global search`, { keywords });
}

export function trackImporterSubmit(context, { importType }) {
  track('imported', context, { importType });
}

export function trackFileChosen(context) {
  track('file chosen', context);
}

export function trackReportCreated(context, dataType, fileType) {
  track('report created', context, { dataType, fileType });
}

export function trackPublicRecordResultsFiltered(context, filter, input) {
  track('public record - results filtered', context, { filter, input });
}

export function trackPublicRecordResultsDateRangeFiltered(context, startDate, endDate) {
  const input = `${startDate} to ${endDate}`;
  const filter = 'date range';
  track('public record - results filtered', context, { filter, input });
}

export function trackPublicRecordColumnSorted(context, column, direction) {
  track('public record - column sorted', context, { column, direction });
}

export function trackPublicRecordPageVisited(context, page) {
  track('public record - page visited', context, { page });
}

export function trackPublicRecordSavedToTopic(context, type, title) {
  track('public record - result saved to topic', context, { type, title });
}

export function trackHelpCenterPageVisited(context) {
  track('visited', `${PLATFORM_HELP_CONTEXT} - ${context}`);
}

export function trackHelpCenterDialogOpen() {
  track('dialog opened', PLATFORM_HELP_CONTEXT);
}
