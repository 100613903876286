import PropTypes from 'prop-types';

export const advancedSearchShape = PropTypes.shape({
  lastSearchId: PropTypes.number,
  ui: PropTypes.shape({
    isSavedSearchesLoading: PropTypes.bool,
    isRecentSearchesLoading: PropTypes.bool,
    isSavingSearches: PropTypes.bool,
    isRemovingItem: PropTypes.bool,
  }),
});
