import { track } from './index';
import { DASHBOARD_CONTEXT } from './contexts';

function trackDashboardEvent(event, data = null) {
  track(event, DASHBOARD_CONTEXT, data);
}

export function trackKeywordNotificationFrequencyChanged(frequency) {
  trackDashboardEvent('keyword notification frequency changed', { frequency });
}

export function trackTopicCreated() {
  trackDashboardEvent('topic created');
}

export function trackTopicDeleted() {
  trackDashboardEvent('topic deleted');
}

export function trackTopicItemDeleted() {
  trackDashboardEvent('topic item deleted');
}

export function trackTopicItemVisited(type) {
  trackDashboardEvent('topic item visited', { type });
}

export function trackTopicsFiltered(type) {
  trackDashboardEvent('topics filtered', { type });
}

export function trackKeywordDeleted() {
  trackDashboardEvent('keyword deleted');
}

export function trackKeywordUpdated() {
  trackDashboardEvent('keyword tracked');
}

export function trackKeywordTracked() {
  trackDashboardEvent('keyword tracked');
}

export function trackTrackingCenterFiltered(dataType, filterType) {
  trackDashboardEvent('tracking center - filtered', { dataType, filterType });
}
