export const MY_FEED = 'myfeed';

export const TWITTER = 'twitter';
export const FACEBOOK = 'facebook';
export const LINKEDIN = 'linkedin';
export const EMAIL_ID = 'mail';

export const FACEBOOK_LINK = 'https://www.facebook.com';
export const TWITTER_LINK = 'https://twitter.com';
export const INSTAGRAM_LINK = 'https://instagram.com';
export const LINKEDIN_LINK = 'https://linkedin.com';
