import * as settings from './settings.scss';

const largeNavHeaderHeight = parseInt(settings.largeNavHeaderHeight, 10);
const mediumNavHeaderHeight = parseInt(settings.mediumNavHeaderHeight, 10);
const navV2Height = parseInt(settings.navV2Height, 10);

export default function getDesktopNavBarHeight({ isDesktopLarge }) {
  const navigationEl = document.getElementById('navigation');

  if (navigationEl && navigationEl.classList.contains('v2')) {
    return navV2Height;
  }

  return isDesktopLarge ? largeNavHeaderHeight : mediumNavHeaderHeight;
}
